import React, { useState, useEffect } from "react";
import RootLayout from "../../components/Layout/RootLayout";
import { useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import WelcomeCard from "../../components/WelcomeCard/WelcomeCard";
import EmployeeDirectory from "../../components/EmployeeDirectory/EmployeeDirectory";
import Shortcuts from "../../components/Shortcuts/Shortcuts";
import customAxios from "../../components/AxiosInterceptor/AxiosInterceptor";
import Events from "../../components/Events/Events";
import Announcements from "../../components/Annoucements/Announcements";

function Home() {
  let location = useLocation();
  const [loading, setLoading] = useState(true);
  const [hasCheckedIn, setHasCheckedIn] = useState(true);
  const [profile, setProfile] = useState();
  let [isOpen, setIsOpen] = useState(!location.state ? false : true);

  function closeModal() {
    setIsOpen(false);
  }

  async function getProfile() {
    customAxios.get("/selfservice/Employee").then((res) => {
      if (res) {
        setProfile(res);
        setLoading(false);
      } else {
        console.log(res);
      }
    });
  }

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <RootLayout>
        <div className="absolute top-0 w-[100vw] z-10 h-[20vh] bg-primary" />
        {!loading && (
          <div className="px-5 sticky z-40 top-10">
            <WelcomeCard
              name={profile[0]?.name ? profile[0]?.name : "Demo User"}
              hasCheckedIn={hasCheckedIn}
            />
          </div>
        )}
        <div className="overflow-auto sticky top-60 max-h-[71vh] pt-5">
          <div className="px-5 space-y-5 pb-5">
            {/* Shortcuts */}
            <Shortcuts />
          </div>
          <EmployeeDirectory />
          <div className="flex flex-col space-y-3">
            <Events />
            <Announcements />
          </div>
        </div>
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black-900 bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div class="sm:flex sm:items-start">
                        <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-50 sm:mx-0 sm:h-10 sm:w-10">
                          <svg
                            class="h-6 w-6 text-orange-800"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z"
                            />
                          </svg>
                        </div>
                        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <h3
                            class="text-lg font-medium leading-6 text-gray-900"
                            id="modal-title"
                          >
                            Demo Usage Notice
                          </h3>
                          <div class="mt-2">
                            <p class="text-sm text-grey-500">
                              Please note that this application is not fully
                              functional as this demo application limits the
                              functionality just for demo purposes.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 flex justify-center items-center w-full">
                        <button
                          type="button"
                          className="inline-flex justify-center w-full rounded-md border border-transparent bg-orange-50 px-4 py-2 text-sm font-medium text-orange-900 hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 focus-visible:ring-offset-2"
                          onClick={closeModal}
                        >
                          Got it, thanks!
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </RootLayout>
    </>
  );
}

export default Home;
