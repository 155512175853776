import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import customAxios from "../../components/AxiosInterceptor/AxiosInterceptor";

function Index({ route, navigation }) {
  let navigate = useNavigate();
  let location = useLocation();
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const passwordRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const initialValues = {
    companyId: "E-0001",
    email: "demo@echesconsultancy.com",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    companyId: Yup.string().required("Company ID is required"),
    password: Yup.string().required("Password is required"),
    email: Yup.string().email().required("Email is required"),
  });

  useEffect(() => {
    localStorage.setItem("theme", "dark");
  }, []);

  const onSubmit = async (
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) => {
    console.log(values);
    setIsSubmit(true);
    Axios.post(`https://api.echeshr.com/api/v1/auth/login`, values).then(
      (res) => {
        if (res.status === 200) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("companyId", res.data.companyId);
          localStorage.setItem("tenantId", res.data.tenantId);
          navigate("/home", { state: { firstTime: true } });
          resetForm({ values: "" });
        } else {
          console.log(res);
          setError(true);
          // setErrorMessage(res.data.message);
          setIsSubmit(false);
        }
      },
      (err) => {
        setErrorMessage(err.response.data.message);
        setError(true);
      }
    );

    // customAxios.post("/auth/login", values).then(
    //   (res) => {
    //     localStorage.setItem("token", res.token);
    //     localStorage.setItem("companyId", res.companyId);
    //     localStorage.setItem("tenantId", res.tenantId);
    //     navigate("/home", { state: { firstTime: true } });
    //     resetForm({ values: "" });
    //   },
    //   (err) => {
    //     console.log(error);
    //     setError(true);
    //     setErrorMessage(err.message);
    //     setIsSubmit(false);
    //   }
    // );

    //save your form data
    //await signatoryInfoCreateAction(fd)
  };

  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  return (
    <section className="h-screen p-5 w-screen flex flex-col justify-start items-center">
      <img
        class="mx-auto h-8 mb-5  w-auto animate-rotate"
        src="https://tailwindui.com/img/logos/mark.svg?color=white"
        alt="Your Company"
      />
      <div className="flex flex-col justify-center items-center p-5 pb-10 text-center bg-grey-50 w-full rounded-xl shadow-xl drop-shadow-xl">
        <div
          data-aos="fade-in"
          data-aos-once="true"
          className="lg:text-3xl text-2xl  text-black-500 "
        >
          <div className="mb-5 space-y-2">
            <h1 className="text-sm mt-4 font-medium">
              Eches Employee Self Service Portal
            </h1>
            {location.state && (
              <p className="text-xs text-black-400">
                The credentials for the demo user was sent to{" "}
                <span className="text-primary">
                  +960
                  {location.state.phone}.
                </span>{" "}
                Please check your inbox.
              </p>
            )}
          </div>
        </div>
        {error && (
          <div className="w-full pb-2">
            {/* Error and Success Server Message */}
            {error && (
              <div
                className={`w-full ${
                  error == null ? "bg-success" : "bg-red-100"
                } p-2 rounded-xl`}
              >
                <div className="flex flex-row space-x-3 justify-start items-center">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-10 h-10 text-red-800"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <div className="flex-col justify-start items-start text-left">
                    <h1 className="text-xs font-medium">
                      {error ? "Error" : "Successful"}
                    </h1>
                    <div className="text-xs text-grey-900">{errorMessage}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <>
          <div
            data-aos="fade-up"
            data-aos-once="true"
            className="lg:bg-lightblack  w-full lg:mt-10   rounded-lg lg:drop-shadow-xl"
          >
            <FormikProvider value={formik}>
              <div>
                <Form>
                  <div className="flex text-sm  space-y-2 lg:text-lg items-start justify-start mb-5 text-left w-full flex-col">
                    <div className="grid grid-cols-1 gap-y-1 w-full">
                      <label
                        className="text-black-500 font-medium text-xs"
                        htmlFor="companyId"
                      >
                        {" "}
                        Company ID{" "}
                      </label>
                      <Field
                        disabled={location.state ? true : false}
                        name="companyId"
                        type="text"
                        className="form-control p-2 focus:border-secondary focus:ring-0 focus:outline-none rounded-lg border border-black-200 shadow-sm"
                      />
                      <ErrorMessage
                        name="companyId"
                        component="div"
                        className="text-red-600 text-sxs"
                      />
                    </div>

                    <div className="grid grid-cols-1 gap-y-1 w-full">
                      <label
                        className="text-black-500 font-medium text-xs"
                        htmlFor="email"
                      >
                        {" "}
                        Email{" "}
                      </label>
                      <Field
                        disabled={location.state ? true : false}
                        name="email"
                        type="text"
                        className="form-control p-2 focus:border-secondary focus:ring-0 focus:outline-none rounded-lg border border-black-200 shadow-sm"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-600 text-sxs"
                      />
                    </div>
                    <div className="grid grid-cols-1 gap-y-1 w-full">
                      <label
                        className="text-black-500 font-medium text-xs"
                        htmlFor="password"
                      >
                        {" "}
                        Password{" "}
                      </label>

                      <Field
                        name="password"
                        type="password"
                        className="form-control p-2 focus:border-secondary shadow-inner-none focus:ring-0 focus:outline-none rounded-lg border border-black-200 "
                      />

                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-600 text-sxs"
                      />
                    </div>
                  </div>

                  <div className="lg:mt-10 mt-5">
                    <button
                      // disabled={isSubmit ? true : false}
                      type="submit"
                      className="text-xs text-white w-full bg-orange-800 px-4 p-2 rounded-xl "
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              </div>
            </FormikProvider>
          </div>
        </>
      </div>
    </section>
  );
}

export default Index;
