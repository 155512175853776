import React, { useState, useEffect, Fragment } from "react";
import RootLayout from "../../components/Layout/RootLayout";
import moment, { months } from "moment/moment";
import {
  Disclosure,
  Tab,
  Transition,
  Dialog,
  Listbox,
} from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/outline";
import { XIcon, CheckIcon } from "@heroicons/react/solid";
import Card from "../../ui/Card/Card";
import { format } from "date-fns";

const clients = [
  {
    id: 1,
    name: "Eches Pvt Ltd",
  },
  {
    id: 2,
    name: "Sibco",
  },
  {
    id: 3,
    name: "Coload",
  },
];

const taskStat = [
  { name: "All", count: 3 },
  { name: "completed", count: 3 },
  { name: "in progress", count: 3 },
  { name: "overdue", count: 3 },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const projects = [
  {
    id: 1,
    name: "Eches Hr V1",
    type: "Software Capitalization",
  },
  {
    id: 2,
    name: "Eches Hr V2",
    type: "Software Capitalization",
  },
  {
    id: 3,
    name: "TaskSheet 2.0",
    type: "Operratioanl",
  },
  {
    id: 4,
    name: "Others",
    type: "Operratioanl",
  },
  {
    id: 5,
    name: "Test",
    type: "Operratioanl",
  },
];

const taskTypes = [
  {
    name: "My Tasks",
  },
  { name: "My Timesheet" },
];

const taskStatus = [
  {
    name: "All",
    count: 50,
  },
  {
    id: 1,
    name: "New",
    count: 10,
  },
  {
    id: 2,
    name: "Verified",
    count: 5,
  },
  {
    id: 3,
    name: "Completed",
    count: 10,
  },
  {
    id: 4,
    name: "Cancelled",
    count: 3,
  },
  {
    id: 5,
    name: "In Progress",
    count: 2,
  },
  {
    id: 6,
    name: "Assigned",
    count: 5,
  },
  {
    id: 7,
    name: "On Hold",
    count: 1,
  },
  {
    id: 8,
    name: "Monitoring",
    count: 2,
  },
];

const tasks = [
  {
    id: 1,
    Customer: "Customer 1",
    Case: "Case 1",
    Task: "Task 1",
    Activity: "Activity 1",
    EmpId: "EmpId 1",
    Status: 2,
    DueDate: new Date(),
    TimeSpent: "00:03:00",
  },
  {
    id: 2,
    Customer: "Customer 2",
    Case: "Case 2",
    Task: "Task 2",
    Activity: "Activity 2",
    EmpId: "EmpId 2",
    Status: 3,
    DueDate: new Date(),
    TimeSpent: "00:03:00",
  },
];

const timeSheet = [
  {
    id: 1,
    Customer: "ABC",
    Task: "Add Timesheet UI",
    Activity: "Add timesheet module to ESSP",
    EmpId: "123",
    Date: "12/12/2021",
    StartTime: "10:00",
    EndTime: "12:00",
    Duration: "2 hours",
    Remarks: "Some Remarks",
  },
  {
    id: 2,
    Customer: "ABC",
    Task: "Timesheet API Integration",
    Activity: "Add timesheet module",
    EmpId: "123",
    Date: "12/12/2021",
    StartTime: "13:00",
    EndTime: "15:00",
    Duration: "2 hours",
    Remarks: "Changed Some other Remarks",
  },
  {
    id: 3,
    Customer: "ABC",
    Task: "Timesheet API Integration",
    Activity: "Add timesheet module",
    EmpId: "123",
    Date: "12/12/2021",
    StartTime: "13:00",
    EndTime: "15:00",
    Duration: "2 hours",
    Remarks: "Changed Some other Remarks",
  },
  {
    id: 4,
    Customer: "ABC",
    Task: "Timesheet API Integration",
    Activity: "Add timesheet module",
    EmpId: "123",
    Date: "12/12/2021",
    StartTime: "13:00",
    EndTime: "15:00",
    Duration: "2 hours",
    Remarks: "Changed Some other Remarks",
  },
  {
    id: 5,
    Customer: "ABC",
    Task: "Timesheet API Integration",
    Activity: "Add timesheet module",
    EmpId: "123",
    Date: "12/12/2021",
    StartTime: "13:00",
    EndTime: "15:00",
    Duration: "2 hours",
    Remarks: "Changed Some other Remarks",
  },
  {
    id: 6,
    Customer: "ABC",
    Task: "Timesheet API Integration",
    Activity: "Add timesheet module",
    EmpId: "123",
    Date: "12/12/2021",
    StartTime: "13:00",
    EndTime: "15:00",
    Duration: "2 hours",
    Remarks: "Changed Some other Remarks",
  },
  {
    id: 7,
    Customer: "ABC",
    Task: "Timesheet API Integration",
    Activity: "Add timesheet module",
    EmpId: "123",
    Date: "12/12/2021",
    StartTime: "13:00",
    EndTime: "15:00",
    Duration: "2 hours",
    Remarks: "Changed Some other Remarks",
  },
  {
    id: 8,
    Customer: "ABC",
    Task: "Timesheet API Integration",
    Activity: "Add timesheet module",
    EmpId: "123",
    Date: "12/12/2021",
    StartTime: "13:00",
    EndTime: "15:00",
    Duration: "2 hours",
    Remarks: "Changed Some other Remarks",
  },
  {
    id: 9,
    Customer: "ABC",
    Task: "Timesheet API Integration",
    Activity: "Add timesheet module",
    EmpId: "123",
    Date: "12/12/2021",
    StartTime: "13:00",
    EndTime: "15:00",
    Duration: "2 hours",
    Remarks: "Changed Some other Remarks",
  },
  {
    id: 10,
    Customer: "ABC",
    Task: "Timesheet API Integration",
    Activity: "Add timesheet module",
    EmpId: "123",
    Date: "12/12/2021",
    StartTime: "13:00",
    EndTime: "15:00",
    Duration: "2 hours",
    Remarks: "Changed Some other Remarks",
  },
];

function Status(props) {
  switch (props.values) {
    case 1:
      return "New";
    case 2:
      return "Verified";
    case 3:
      return "Completed";
    case 4:
      return "Cancelled";
    case 5:
      return "In Progress";
    case 6:
      return "Assigned";
    case 7:
      return "On Hold";
    case 8:
      return "Monitoring";
    default:
      return "UnAssigned";
  }
}

function Timesheet() {
  const [loading, setLoading] = useState(true);
  const [selectedTask, setSelectedTask] = useState("My Tasks");
  const [isOpen, setIsOpen] = useState(false);
  const [success, setSuccess] = useState("hello");
  const [error, setError] = useState(null);
  const [selectedProjects, setSelectedProjects] = useState(projects[0]);
  const [selectedClient, setSelectedClient] = useState(clients[0]);
  const [cases, setCases] = useState();

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setError(null);
    setSuccess("");
    setIsOpen(false);
  }

  function filter() {}

  return (
    <>
      <RootLayout>
        <div className="">
          <div
            style={{ zIndex: 20 }}
            className="bg-primary  dark:bg-black-700 active:bg-orange-700 shadow-2xl fixed  flex items-center justify-center bottom-24 right-5 rounded-full p-4"
          >
            <div>
              <PlusIcon
                onClick={() => openModal()}
                className="w-8 h-8 text-white dark:text-primary"
              />
            </div>
          </div>
          <div className="w-full sm:px-0">
            <Tab.Group>
              <div
                style={{ zIndex: 10 }}
                className="shadow-md px-5  pb-3   sticky top-14 space-y-4 "
              >
                <Tab.List className="flex w-full  justify-between   space-x-3  rounded-xl bg-orange-50 dark:bg-black-900 p-1">
                  {taskTypes.map((type, index) => (
                    <Tab
                      key={index}
                      onClick={() => setSelectedTask(type.name)}
                      className={({ selected }) =>
                        classNames(
                          " rounded-lg   py-1 px-3 w-full text-xs font-medium leading-5 ",
                          " focus:outline-none",
                          selected
                            ? "bg-primary text-white shadow"
                            : "text-primary hover:bg-white/[0.12] hover:text-white"
                        )
                      }
                    >
                      {type.name}
                    </Tab>
                  ))}
                </Tab.List>
              </div>
              {taskTypes.map((type, index) => (
                <>
                  <Tab.Panel
                    className={
                      "bg-black-100 h-[80vh]  pt-4 pb-14 space-y-2 focus:ring-0 focus:outline-none sticky top-20"
                    }
                  >
                    {/* Heading with filters */}
                    <div className="flex flex-row px-5 justify-between">
                      <div className="text-xl font-medium ">{type.name}</div>
                      {/* <div className="flex flex-row justify-between items-center space-x-5">
                        <div className="bg-primary text-white font-medium h-8 w-8 rounded-full flex justify-center items-center">
                          10
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                          onClick={() => openModal()}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                          />
                        </svg>
                      </div> */}
                    </div>

                    <div className="flex flex-row space-x-2 py-3 pl-5 overflow-auto justify-start items-center max-w-[100vw]">
                      {taskStatus.map((status, index) => (
                        <div className="min-w-[100px]  bg-primary rounded-md min-h-fit text-white shadow-md p-2">
                          <p className="text-xl font-medium">{status.count}</p>
                          <p className="text-xs text-black-200">
                            {status.name}
                          </p>
                        </div>
                      ))}
                    </div>

                    {/* Day Wise Tasks */}
                    <div className="h-[60vh] overflow-auto px-5 space-y-4">
                      {tasks.map((task) => (
                        <Disclosure defaultOpen>
                          <div className="flex flex-col space-y-3">
                            <Disclosure.Button
                              className={
                                "w-full flex justify-between space-x-50 items-center"
                              }
                            >
                              <p className="font-medium">{task.Case}</p>
                              <div className=" flex-row">
                                <p className="text-xs ">
                                  {format(task?.DueDate, "dd MMMM yyyy")}
                                </p>
                              </div>
                            </Disclosure.Button>

                            {/* {tasks.map((task) => (
                          ))} */}

                            <Transition
                              className={"space-y-3"}
                              enter="transition duration-100 ease-out"
                              enterFrom="transform scale-95 opacity-0"
                              enterTo="transform scale-100 opacity-100"
                              leave="transition duration-75 ease-out"
                              leaveFrom="transform scale-100 opacity-100"
                              leaveTo="transform scale-95 opacity-0"
                            >
                              <Disclosure.Panel
                                className={
                                  "flex flex-col justify-center items-start space-x-3  rounded-md shadow-md p-3 border border-black-200 rounded-m "
                                }
                              >
                                <div>
                                  <div className="grid grid-cols-2 gap-x-40">
                                    <p
                                      style={{ fontSize: 12 }}
                                      className={`capitalize  ${
                                        task.Status == "completed"
                                          ? "text-success"
                                          : "text-primary"
                                      }`}
                                    >
                                      <Status values={task.Status} />
                                    </p>
                                    <p className="text-xs">
                                      Time Spent {task.TimeSpent}
                                    </p>
                                  </div>
                                  <p className="text-sm font-medium">
                                    {task.Task}
                                  </p>
                                  <p className="text-sm text-black-400">
                                    {task.Activity}
                                  </p>
                                </div>
                              </Disclosure.Panel>
                            </Transition>
                          </div>
                        </Disclosure>
                      ))}
                    </div>
                  </Tab.Panel>

                  <Tab.Panel
                    className={
                      "bg-black-100 h-[80vh]  pt-4 pb-14 space-y-2 focus:ring-0 focus:outline-none sticky top-20"
                    }
                  >
                    {/* Heading with filters */}
                    <div className="flex flex-row px-5 justify-between">
                      <div className="text-xl font-medium ">{type.name}</div>
                      <div className="flex flex-row justify-between items-center space-x-5">
                        <div className="bg-primary text-white font-medium h-8  rounded-lg flex justify-center items-center">
                          <p className="m-5">Total Hours: 4</p>
                        </div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="h-[60vh] overflow-auto px-5 space-y-4">
                      {timeSheet.map((time) => (
                        <Disclosure defaultOpen>
                          <Disclosure.Panel
                            className={
                              "flex flex-col justify-center items-start space-x-3  rounded-md shadow-md p-3 border border-black-200 rounded-m"
                            }
                          >
                            <div className="grid grid-flow-col auto-cols-max">
                              <div className="flex flex-col justify-between h-full">
                                <p className="text-sm font-medium">
                                  {time.StartTime}
                                </p>
                                {/* repeat dotted line for full height */}
                                <div className="h-full border-l-2 border-black-200"></div>

                                <p className="text-sm font-medium">
                                  {time.EndTime}
                                </p>
                              </div>
                              <div className="ml-5">
                                <p className="text-sm font-medium">
                                  {time.Customer}
                                </p>
                                <p className="text-sm">{time.Task}</p>
                                <p className="text-sm">{time.Activity}</p>
                                <p className="text-sm">{time.Remarks}</p>
                              </div>
                            </div>
                          </Disclosure.Panel>
                        </Disclosure>
                      ))}
                    </div>
                  </Tab.Panel>
                </>
              ))}
            </Tab.Group>
          </div>

          <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={closeModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black-900 bg-opacity-80" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto z-100">
                <div className="flex min-h-full items-center justify-center p-4 text-center z-100">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-100 translate-y-[200px]"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Panel className="w-full min-h-fits max-w-screen transform overflow-hidden rounded-2xl bg-white dark:bg-black-800 border border-white dark:border-black-600 p-6 text-left align-middle shadow-xl transition-all">
                      <div className="flex flex-row justify-between items-center">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-black-900 dark:text-white"
                        >
                          Add Case
                        </Dialog.Title>
                        <XIcon
                          onClick={closeModal}
                          className="w-4 h-4 text-black-700 dark:text-white active:text-primary"
                        />
                      </div>
                      {/* Client Selection */}
                      <div className="z-100 mt-2">
                        <Listbox
                          value={selectedClient}
                          onChange={setSelectedClient}
                        >
                          <div className="relative mt-1 z-100">
                            <p className="text-sm text-black-600 dark:text-white  font-medium">
                              Select a Client
                            </p>
                            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm z-100">
                              <span className="block truncate">
                                {selectedClient.name}
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute mt-1 max-h-60 w-auto overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-100">
                                {clients.map((client) => (
                                  <Listbox.Option
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                        active
                                          ? "bg-amber-100 text-amber-900"
                                          : "text-gray-900"
                                      }`
                                    }
                                    key={client.id}
                                    value={client}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate ${
                                            selected
                                              ? "font-medium"
                                              : "font-normal"
                                          }`}
                                        >
                                          {client.name}
                                        </span>
                                        {selected ? (
                                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>

                      {/* Project Selection */}
                      <div className="z-0 mt-2">
                        <Listbox
                          value={selectedProjects}
                          onChange={setSelectedProjects}
                        >
                          <div className="relative mt-1">
                            <p className="text-sm text-black-600 dark:text-white  font-medium">
                              Select a Project
                            </p>
                            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                              <span className="block truncate">
                                {selectedProjects.name}
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute mt-1 max-h-60 w-auto overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {projects.map((project) => (
                                  <Listbox.Option
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                        active
                                          ? "bg-amber-100 text-amber-900"
                                          : "text-gray-900"
                                      }`
                                    }
                                    key={project.id}
                                    value={project}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate ${
                                            selected
                                              ? "font-medium"
                                              : "font-normal"
                                          }`}
                                        >
                                          {project.name}
                                        </span>
                                        {selected ? (
                                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>

                      {/* Add Case */}
                      <div className="flex flex-col space-y-2 mt-2">
                        <h1 className="text-sm text-black-600 dark:text-white  font-medium">
                          Case
                        </h1>
                        <input
                          type="text"
                          onChange={(e) => setCases(e.target.value)}
                          className="p-3 border border-black-200 dark:border-black-500 bg-black-100 text-black-800 dark:text-white dark:bg-black-800"
                        />
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </div>
      </RootLayout>
    </>
  );
}

export default Timesheet;
