import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie-player";
import OfficeAnimation from "../../../assets/lotties/office.json";
import Header from "../../../components/Header/Header";
import customAxios from "../../../components/AxiosInterceptor/AxiosInterceptor";
import { XCircleIcon } from "@heroicons/react/outline";

export default function Register() {
  let navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [phone, setPhone] = useState("");

  const initialValues = {
    fullName: "",
    email: "",
    phone: "",
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    phone: Yup.string("Phone should be of numbers 0-9")
      .required("Phone is required")
      .max(7, "Number should be exactly 7 digits"),
    email: Yup.string().email().required("Email is required"),
  });

  useEffect(() => {
    localStorage.setItem("theme", "dark");
  }, []);

  const onSubmit = async (
    values,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) => {
    let data = {
      userName: values.fullName,
      email: values.email,
      phoneNumber: values.phone,
    };
    customAxios.post("/demouser/signup", data).then(
      (res) => {
        setIsSubmit(true);
        navigate("/login", { state: { phone: values.phone } });
      },
      (err) => {
        setError(true);
      }
    );

    //save your form data
    //await signatoryInfoCreateAction(fd)
  };

  const formik = useFormik({ initialValues, onSubmit, validationSchema });

  return (
    <div className="h-screen w-screen flex px-5 flex-col justify-start items-center">
      <img
        class="mx-auto h-8 mb-5  w-auto animate-rotate"
        src="https://tailwindui.com/img/logos/mark.svg?color=white"
        alt="Your Company"
      />
      <div className="flex flex-col justify-center items-center p-5 text-center bg-grey-50 w-full rounded-xl shadow-xl drop-shadow-xl">
        {/* Lottie files */}
        <Lottie
          loop
          animationData={OfficeAnimation}
          play
          style={{ width: 200, height: 200 }}
        />
        <div className="mb-5 space-y-2">
          <h1 className="font-medium ">Welcome to EchesHR ESSP Demo</h1>
          <span className="text-primary text-xs">
            Add this app to home screen on iOS or install the app on Android
            devices from the browser for best results
          </span>{" "}
          <p className="text-xs  text-black-400">
            Please provide the following information to proceed.{" "}
          </p>
          {error && (
            <div className="w-full pt-2">
              {/* Error and Success Server Message */}
              {error && (
                <div
                  className={`w-full ${
                    error == null ? "bg-success" : "bg-red-100"
                  } p-2 rounded-xl`}
                >
                  <div className="flex flex-row space-x-3 justify-start items-center">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-10 h-10 text-red-800"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                    <div className="flex-col justify-start items-start text-left">
                      <h1 className="text-xs font-medium">
                        {error ? "Error" : "Successful"}
                      </h1>
                      <div className="text-xs text-grey-900">
                        There was a problem signing you up for the demo. Please
                        try again!
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Form */}
        <FormikProvider value={formik}>
          <div className="w-full">
            <Form>
              <div className="flex text-xs   space-y-2 lg:text-lg items-start justify-start mb-5 text-left w-full flex-col">
                <div className="grid grid-cols-1 gap-y-1 w-full">
                  <label
                    className="text-black-500 font-medium text-xs"
                    htmlFor="fullName"
                  >
                    Full Name
                  </label>
                  <Field
                    name="fullName"
                    type="text"
                    className="form-control p-2 focus:border-secondary focus:ring-0 focus:outline-none rounded-lg border border-black-200 shadow-sm"
                  />
                  <ErrorMessage
                    name="fullName"
                    style={{ fontSize: 11 }}
                    component="div"
                    className="text-red-600 text-xs"
                  />
                </div>

                <div className="grid grid-cols-1 gap-y-1 w-full">
                  <label
                    className="text-black-500 font-medium text-xs"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <Field
                    name="email"
                    type="text"
                    className="form-control p-2 focus:border-secondary focus:ring-0 focus:outline-none  rounded-xl  border border-black-200 shadow-sm"
                  />
                  <ErrorMessage
                    name="email"
                    style={{ fontSize: 11 }}
                    component="div"
                    className="text-red-600 text-xs"
                  />
                </div>
                <div className="grid grid-cols-1 gap-y-1 w-full">
                  <label
                    className="text-black-500 font-medium text-xs"
                    htmlFor="phone"
                  >
                    Phone
                  </label>
                  <div className="flex flex-row w-full">
                    <div className="p-2 px-2 text-grey-500 bg-grey-200 border border-black-200 rounded-l-xl">
                      +960
                    </div>
                    <Field
                      name="phone"
                      type="phone"
                      className="form-control p-2 focus:border-secondary w-full  focus:ring-0   focus:outline-none rounded-r-xl border border-black-200 "
                    />
                  </div>

                  <ErrorMessage
                    name="phone"
                    style={{ fontSize: 11 }}
                    component="div"
                    className="text-red-600 text-xs"
                  />
                </div>
              </div>

              <div className="lg:mt-10 w-full mt-10">
                <button
                  type="submit"
                  className="text-xs text-white w-full bg-orange-800 px-4 p-2 rounded-xl "
                >
                  Register
                </button>
              </div>
            </Form>
          </div>
        </FormikProvider>
      </div>
      <div className="mt-5 text-white text-xs text-center">
        <p>
          For more information regarding Eches HR, Please visit this{" "}
          <span>
            <a
              className="underline"
              target="_blank"
              rel="noopener noreferrer"
              href="https://echesconsultancy.com"
            >
              link
            </a>
          </span>
          {""} or contact +960 7790989
        </p>
      </div>
    </div>
  );
}
