import PageRoutes from "./routes/Routes";
import React, { useState, useEffect } from "react";

function App() {
  return (
    <PageRoutes>
      <div className="App"></div>
    </PageRoutes>
  );
}

export default App;
