import React from "react";
import Construction from "../../components/Construction/Construction";
import WebsiteLayout from "../../components/Layout/RootLayout";

function NotFound() {
  return (
    <WebsiteLayout>
      <div className="px-5 h-screen w-screen flex justify-center items-center">
        <Construction />
      </div>
    </WebsiteLayout>
  );
}

export default NotFound;
