import React, { useState, useEffect, Fragment } from "react";
import WebsiteLayout from "../../../components/Layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import customAxios from "../../../components/AxiosInterceptor/AxiosInterceptor";
import SubHeading from "../../../ui/SubHeading/SubHeading";
import Card from "../../../ui/Card/Card";
import { format } from "date-fns";
import { Dialog, Transition } from "@headlessui/react";
import SettingsPopOver from "../../../components/SettingsPopOver/SettingsPopOver";

const personalDetails = {
  name: "Eches User",
  gender: "M",
  DOB: new Date(),
  Nationality: "Maldivian",
  Married: false,
};

const dependencyDetails = [
  {
    name: "Asiyath Hinfa",
    relation: "Mother",
    documents: [],
  },
  {
    name: "Mohamed Haneef",
    relation: "Father",
    documents: [],
  },
];

const empDetails = {
  empId: 124,
  designation: "Full-Stack Developer",
  department: "IT Development",
  unit: "Male'",
  division: "Ameenee Magu Branch",
  joinedDate: new Date(),
  reportsTo: {
    name: "Mohamed Ibrahim",
    designation: "Tech Lead",
  },
  teamMembers: [
    {
      name: "Mohamed Siyah",
      designation: "Developer",
      phone: "+9607777283",
      email: "siyah@echesconsultancy.com",
    },
    {
      name: "Mohamed Raffan",
      designation: "React Developer",
      phone: "+9607777283",
      email: "siyah@echesconsultancy.com",
    },
    {
      name: "Hassan Nihan",
      designation: ".NET Developer",
      phone: "+9607777283",
      email: "siyah@echesconsultancy.com",
    },
  ],
};

function MyProfile() {
  let navigate = useNavigate();
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(false);
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  async function getProfile() {
    customAxios
      .get("https://api.echeshr.com/api/v1/selfservice/Employee")
      .then((res) => {
        if (res) {
          setProfile(res[0]);
          setLoading(false);
        } else {
          console.log(res);
        }
      });
  }

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <WebsiteLayout>
        <div className="absolute top-0 w-[100vw] z-10 h-[12vh] bg-primary" />
        {!loading && (
          <>
            <div className=" relative flex flex-col space-y-1 ">
              {/* Year and Month Select */}
              <div className="flex flex-col z-40 px-5 py-3  rounded-xl space-y-1 justify-start items-center">
                {/* Profile Picture */}
                <div className="w-full flex flex-row justify-between items-start ">
                  <div
                    style={{
                      backgroundImage: `url('https://img.freepik.com/free-photo/close-up-young-successful-man-smiling-camera-standing-casual-outfit-against-blue-background_1258-66609.jpg?w=2000')`,
                    }}
                    className="bg-cover border-2 border-white bg-center h-[100px] w-[100px] rounded-full"
                  />
                  <div className="flex space-x-5 flex-row justify-end items-end">
                    <svg
                      onClick={() => setIsOpen(true)}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-8 h-8 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495"
                      />
                    </svg>
                  </div>
                </div>
                {/* name and stuff */}
                <div className="w-full flex flex-col justify-start pb-3 space-y-0 items-start ">
                  <h1 className="text-2xl w-3/4 font-medium">
                    {profile?.name ? profile?.name : "Arsham Hussain"}
                  </h1>
                  <p className="text-primary">Fullstack Developer</p>
                </div>
                <div className="flex w-full flex-col space-y-6 h-[65vh] overflow-auto">
                  {/* Personal Details */}
                  <div className="flex flex-col w-full space-y-2  text-left justify-start items-start">
                    <div className="flex  w-full flex-row justify-between items-center">
                      <SubHeading heading="Personal Details" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                        />
                      </svg>
                    </div>

                    <div className="grid grid-cols-2  bg-white shadow rounded-xl font-medium border-black-200 border p-3 w-full gap-3">
                      <div className="flex flex-col space-y-1 text-xs">
                        <h5 className="text-black-500 font-medium capitalize">
                          Name
                        </h5>
                        <p className="text-sm">
                          {profile?.name ? profile?.name : "Demo User"}
                        </p>
                      </div>
                      <div className="flex flex-col space-y-1 text-xs">
                        <h5 className="text-black-500 font-medium capitalize">
                          Nationality
                        </h5>
                        <p className="text-sm">{personalDetails.Nationality}</p>
                      </div>
                      <div className="flex flex-col space-y-1 text-xs">
                        <h5 className="text-black-500 font-medium capitalize">
                          Gender
                        </h5>
                        <p className="text-sm">{personalDetails.gender}</p>
                      </div>
                      <div className="flex flex-col space-y-1 text-xs">
                        <h5 className="text-black-500 font-medium capitalize">
                          Married
                        </h5>
                        <p className="text-sm">
                          {personalDetails.Married ? "Yes" : "No"}
                        </p>
                      </div>
                      <div className="flex flex-col space-y-1 text-xs">
                        <h5 className="text-black-500 font-medium capitalize">
                          Date of Birth:
                        </h5>
                        <p className="text-sm">
                          {format(personalDetails.DOB, "d MMMM yyyy")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Employments Details */}
                  <div className="flex flex-col w-full space-y-2  text-left justify-start items-start">
                    <div className="flex w-full flex-row justify-between items-center">
                      <SubHeading heading="Employment Details" />
                    </div>

                    <div className="grid grid-cols-2  bg-white shadow rounded-xl font-medium border-black-200 border p-3 w-full gap-3">
                      <div className="flex flex-col space-y-1 text-xs">
                        <h5 className="text-black-500 font-medium capitalize">
                          Designation
                        </h5>
                        <p className="text-sm">{empDetails.designation}</p>
                      </div>
                      <div className="flex flex-col space-y-1 text-xs">
                        <h5 className="text-black-500 font-medium capitalize">
                          department
                        </h5>
                        <p className="text-sm">{empDetails.department}</p>
                      </div>
                      <div className="flex flex-col space-y-1 text-xs">
                        <h5 className="text-black-500 font-medium capitalize">
                          Employee Id
                        </h5>
                        <p className="text-sm">{empDetails.empId}</p>
                      </div>
                      <div className="flex flex-col space-y-1 text-xs">
                        <h5 className="text-black-500 font-medium capitalize">
                          Joined Date
                        </h5>
                        <p className="text-sm">
                          {format(empDetails.joinedDate, "d MMMM yyyy")}
                        </p>
                      </div>
                      <div className="flex flex-col space-y-1 text-xs">
                        <h5 className="text-black-500 font-medium capitalize">
                          unit
                        </h5>
                        <p className="text-sm">{empDetails.unit}</p>
                      </div>
                      <div className="flex flex-col space-y-1 text-xs">
                        <h5 className="text-black-500 font-medium capitalize">
                          Division
                        </h5>
                        <p className="text-sm">{empDetails.division}</p>
                      </div>
                    </div>
                  </div>
                  {/* Dependants Details */}
                  <div className="flex flex-col w-full space-y-2  text-left justify-start items-start">
                    <div className="flex w-full flex-row justify-between items-center">
                      <SubHeading heading="Dependants Details" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                        />
                      </svg>
                    </div>
                    <div className="flex  flex-col w-full text-sm space-y-3">
                      {dependencyDetails.map((dd, i) => (
                        <Card key={i}>
                          <div className="flex justify-between font-medium items-center">
                            <p>{dd.name}</p>
                            <p className="font-normal text-black-500">
                              {dd.relation}
                            </p>
                          </div>
                        </Card>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {loading && <>Loading</>}
      </WebsiteLayout>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed  inset-0 bg-black-900 bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 h-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-20"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="w-2/3 h-screen max-w-md transform overflow-hidden  bg-white p-6 text-left  shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Settings
                  </Dialog.Title>
                  <div className="mt-2">
                    <SettingsPopOver />
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-orange-50 px-4 py-1 text-sm font-medium text-primary hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        localStorage.removeItem("token");
                        navigate("/login");
                      }}
                    >
                      Log out
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default MyProfile;
