import React, { useState, Fragment } from "react";
import WebsiteLayout from "../../../components/Layout/RootLayout";
import { Tab, Disclosure, Dialog, Transition } from "@headlessui/react";
import Complaint from "./Complaint";
import { PlusIcon, XIcon } from "@heroicons/react/solid";
import { useDropzone } from "react-dropzone";

/* 
    TODO: 
    - CRUD 
    - Double check dialog for resolve and delete
    - Default page when no complaints made yet
    - add documents to complaint form
*/

const Complaints = [
  // pending
  {
    status: "pending",
    title: "Complaint 1 Title",
    description: "Complaint 1 description",
    postTime: "2:36PM",
    postDate: "9/28/2022",
    respondedPerson: "N/A",
    response: "N/A",
    respondedTime: "N/A",
    respondedDate: "N/A",
    resolvedTime: "N/A",
    resolvedDate: "N/A",
  },
  // responded
  {
    status: "responded",
    title: "Complaint 6 Title",
    description: "Complaint 6 description",
    postTime: "2:36PM",
    postDate: "9/28/2022",
    respondedPerson: "HR Manager",
    response: "Response",
    respondedTime: "2:41 PM",
    respondedDate: "9/28/2022",
    resolvedTime: "N/A",
    resolvedDate: "N/A",
  },
  // resolved
  {
    status: "resolved",
    title: "Complaint 11 Title",
    description: "Complaint 11 description",
    postTime: "2:36PM",
    postDate: "9/28/2022",
    respondedPerson: "HR Manager",
    response: "Response",
    respondedTime: "2:41 PM",
    respondedDate: "9/28/2022",
    resolvedTime: "2:43 PM",
    resolvedDate: "9/28/2022",
  },
];

// filter complaints
let pendingComplaints = [];
let respondedComplaints = [];
let resolvedComplaints = [];

for (let i = 0; i < Complaints.length; i++) {
  if (Complaints[i].status === "pending") {
    pendingComplaints.push(Complaints[i]);
  } else if (Complaints[i].status === "responded") {
    respondedComplaints.push(Complaints[i]);
  } else {
    resolvedComplaints.push(Complaints[i]);
  }
}

// console.log(pendingComplaints)
// console.log(respondedComplaints)
// console.log(resolvedComplaints)

function MyComplaints() {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  let [isComplaintFormOpen, setIsComplaintFormOpen] = useState(false);

  function openComplaintForm() {
    setIsComplaintFormOpen(true);
  }

  function closeComplaintForm() {
    setIsComplaintFormOpen(false);
  }
  return (
    <WebsiteLayout>
      <Tab.Group>
        <Tab.List className=" mx-auto flex w-fit max-w-[95vw] justify-center overflow-x-auto space-x-3 rounded-xl bg-white dark:bg-black-900 p-1 ">
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={
                  selected
                    ? "rounded-lg  min-w-fit py-1 px-3 text-xs font-medium leading-5 focus:outline-none bg-primary text-white shadow"
                    : "rounded-lg  min-w-fit py-1 px-3 text-xs font-medium leading-5 focus:outline-none text-primary hover:bg-white/[0.12] hover:text-white"
                }
              >
                Pending ({pendingComplaints.length})
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={
                  selected
                    ? "rounded-lg  min-w-fit py-1 px-3 text-xs font-medium leading-5 focus:outline-none bg-primary text-white shadow"
                    : "rounded-lg  min-w-fit py-1 px-3 text-xs font-medium leading-5 focus:outline-none text-primary hover:bg-white/[0.12] hover:text-white"
                }
              >
                Responses ({respondedComplaints.length})
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={
                  selected
                    ? "rounded-lg  min-w-fit py-1 px-3 text-xs font-medium leading-5 focus:outline-none bg-primary text-white shadow"
                    : "rounded-lg  min-w-fit py-1 px-3 text-xs font-medium leading-5 focus:outline-none text-primary hover:bg-white/[0.12] hover:text-white"
                }
              >
                Resolved ({resolvedComplaints.length})
              </button>
            )}
          </Tab>
        </Tab.List>
        <Tab.Panels>
          {/* Pending */}
          <Tab.Panel>
            <div
              id="complaintsContainer"
              className="w-[95vw] h-[73vh] m-auto overflow-auto mt-2 pb-5"
            >
              {pendingComplaints.map((x) => (
                <Complaint object={x} />
              ))}
            </div>
          </Tab.Panel>
          {/* Responses */}
          <Tab.Panel>
            <div
              id="complaintsContainer"
              className="w-[95vw] h-[73vh] m-auto overflow-auto mt-2 pb-5"
            >
              {respondedComplaints.map((x) => (
                <Complaint object={x} />
              ))}
            </div>
          </Tab.Panel>
          {/* Resolved */}
          <Tab.Panel>
            <div
              id="complaintsContainer"
              className="w-[95vw] h-[73vh] m-auto overflow-auto mt-2 pb-5"
            >
              {resolvedComplaints.map((x) => (
                <Complaint object={x} />
              ))}
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      {/* Add Complaints Button*/}
      <button
        onClick={openComplaintForm}
        className="bg-primary dark:bg-black-700 active:bg-orange-700 shadow-2xl fixed flex items-center justify-center bottom-24 right-5 rounded-full p-4"
      >
        <div>
          <PlusIcon className="w-8 h-8 text-white dark:text-primary" />
        </div>
      </button>
      {/* Complaint form */}
      <Transition appear show={isComplaintFormOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeComplaintForm}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black-900 bg-opacity-80" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-100 translate-y-[200px]"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0"
              >
                <Dialog.Panel className="w-full h-fit max-w-screen transform overflow-hidden rounded-2xl bg-white dark:bg-black-800 border border-white dark:border-black-600 p-4 text-left align-middle shadow-xl transition-all">
                  <div className="flex flex-row justify-between items-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-black-900 dark:text-white mb-2"
                    >
                      Complaint form
                    </Dialog.Title>
                    <XIcon
                      onClick={closeComplaintForm}
                      className="w-4 h-4 text-black-700 dark:text-white active:text-primary"
                    />
                  </div>
                  <div className=" w-full h-[90%]">
                    <div className="flex flex-col space-y-2 mb-2">
                      <h1 className="text-sm text-black-600 dark:text-white  font-medium">
                        Title
                      </h1>
                      <input
                        type="text"
                        className="p-3 border border-black-200 dark:border-black-500 bg-black-100 text-black-800 dark:text-white dark:bg-black-800"
                      />
                    </div>
                    <div className="flex flex-col space-y-2 mb-2">
                      <h1 className="text-sm text-black-600 dark:text-white  font-medium">
                        Description
                      </h1>
                      <input
                        type="text"
                        className="p-3 border border-black-200 dark:border-black-500 bg-black-100 text-black-800 dark:text-white dark:bg-black-800"
                      />
                      <div className="flex">
                        <input type="checkbox" />
                        <label className="ml-2  font-medium text-sm text-black-600 dark:text-white">
                          Anonymous
                        </label>
                      </div>
                    </div>
                    {/* Attach Documents */}
                    <h1 className="mb-1 font-medium text-sm text-black-600 dark:text-white">
                      Attach documents
                    </h1>
                    <div
                      {...getRootProps({ className: "dropzone" })}
                      className="border dark:border-black-700 dark:bg-black-600 bg-black-200 border-black-300 text-black-600 dark:text-white  focus:outline-none p-2 rounded"
                    >
                      <input {...getInputProps()} />
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    </div>
                    <button
                      onClick={closeComplaintForm}
                      className=" mt-4 bottom-0 m-auto h-[5vh] w-[100%] rounded-md text-xs font-medium bg-primary text-white shadow"
                    >
                      Submit
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </WebsiteLayout>
  );
}

export default MyComplaints;
