import { data } from "autoprefixer";
import React from "react";

export default function FloatingCard(props) {
  return (
    <div className="shadow-md fixed mt-3 mx-5 w-[90vw] z-30 bg-white flex flex-col space-y-4 rounded-xl  p-3 border border-black-200">
      <div className="flex flex-row justify-around  items-center">
        {props?.data?.map((d, i) => (
          <div
            key={i}
            className="flex flex-col justify-center items-center space-y-1"
          >
            <h1 className="text-lg font-medium ">{d?.amount}</h1>
            <p style={{ fontSize: 10 }} className="font-medium text-black-500">
              {d?.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
