import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Card from "../../ui/Card/Card";

function WelcomeCard(props) {
  const [time, setTime] = useState();
  const [Greeting, setGreeting] = useState();

  useEffect(() => {
    getDayTime();
  }, []);

  useEffect(() => {
    // loadProfile();
    const interval = setInterval(function () {
      setTime(moment().format("h:mm:ss a"));
    }, 1000);
  }, []);

  const getDayTime = () => {
    const time = new Date().getHours();
    if (time < 12 && time > 6) {
      setGreeting("Good Morning");
    } else if (time < 17 && time >= 12) {
      setGreeting("Good Afternoon");
    } else if (time < 22 && time >= 17) {
      setGreeting("Good Evening");
    } else {
      setGreeting("Good Night");
    }
  };

  return (
    <div className="">
      <Card>
        <div className="flex flex-row  justify-between items-center">
          {/* Greeting */}
          <div className="flex flex-col justify-center text-black-900 dark:text-black-100 space-y-2 w-7/12">
            <p className="text-xs">{Greeting}</p>
            <p className="font-medium text-xl">{props.name}</p>
            {/* Check In Status */}
            <div className="text-primary">
              {props.hasCheckedIn && (
                <div className="rounded-xl bg-orange-50 dark:bg-primary/10 p-1 px-2 w-fit text-xs text-primary ">
                  Checked In
                </div>
              )}
              {!props.hasCheckedIn && (
                <div className="p-1">
                  <p className="text-xs text-primary">
                    Seems like you have not checked in Today. Check in now!
                  </p>
                </div>
              )}
            </div>
          </div>
          {/* Profile Picture */}
          <div className="flex justify-center items-center w-5/12">
            <div
              style={{
                backgroundImage: `url('https://img.freepik.com/free-photo/close-up-young-successful-man-smiling-camera-standing-casual-outfit-against-blue-background_1258-66609.jpg?w=2000')`,
              }}
              className="bg-cover border-2 border-primary bg-center h-[85px] w-[85px] rounded-full"
            />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default WelcomeCard;
