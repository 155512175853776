import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon, ClockIcon } from "@heroicons/react/outline";
import {
  format,
  addHours,
  endOfDay,
  isBefore,
  eachHourOfInterval,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import moment from "moment";

const timeLog = [
  {
    name: "Meeting",
    startTime: new Date("2022-09-30T09:00:00"),
    endTime: new Date("2022-09-30T10:00:00"),
    remarks: "Checked In",
  },
  {
    name: "Coding",
    startTime: new Date("2022-09-30T10:00:00"),
    endTime: new Date("2022-09-30T12:00:00"),
    remarks: "Checked In",
  },
  {
    name: "Testing",
    startTime: new Date("2022-09-30T11:30:00"),
    endTime: new Date("2022-09-30T12:00:00"),
    remarks: "Checked In",
  },
  {
    name: "Lunch Break",
    startTime: new Date("2022-09-30T12:30:00"),
    endTime: new Date("2022-09-30T13:30:00"),
    remarks: "Checked In",
  },
  {
    name: "Attendance Module UI",
    startTime: new Date("2022-09-30T14:30:00"),
    endTime: new Date("2022-09-30T18:30:00"),
    remarks: "Checked In",
  },
];

const checkIn = new Date("2022-09-30T09:00:00");
let checkOut;
const endTime = !checkOut ? new Date("2022-09-30T23:59:59") : checkOut;
const interval = { start: checkIn, end: endTime };
const timeIntervals = eachHourOfInterval(interval);

const minuteDifference = differenceInMinutes(endTime, checkIn);
const hourDifferecence = differenceInHours(endTime, checkIn) + 1;

function timeRender() {
  return (
    <div className="flex-col">
      {timeIntervals.map((t, i) => (
        <div
          style={{ height: minuteDifference / hourDifferecence }}
          className=""
          key={i}
        >
          <div className="font-medium">
            <p>{moment(t).format("hh:mm")}</p>
            <p>.</p>
            <p>.</p>
          </div>
        </div>
      ))}
    </div>
  );
}

const colors = [
  "#e6fff7",
  "#e6ecff",
  "#f0e6ff",
  "#e6ffe6",
  "#ffe6e6",
  "#ffffe6",
  "#e6fff7",
  "#ffffe6",
];

function eventsRender() {
  let z = 1;
  return (
    <div className="relative">
      {timeLog.map((tl, i) => {
        let timeTaken = differenceInMinutes(tl.endTime, tl.startTime);
        let height = timeTaken + 10;
        let diffCheckInEvent = differenceInMinutes(tl.startTime, checkIn);
        let topDifference = diffCheckInEvent + 2;
        const random = Math.floor(Math.random() * colors.length);
        let randColor = colors[random];

        return (
          <div
            className="absolute shadow-md space-y-1 overflow-hidden border-l-8  rounded-xl  border  border-white  text-primary text-xs font-medium p-2 w-full"
            style={{
              height: height,
              top: topDifference,
              borderLeftColor: randColor,
            }}
          >
            <p>{tl.name}</p>
            {/* <p className="font-normal text-black-900">{tl.remarks}</p> */}
          </div>
        );
      })}
    </div>
  );
}

function renderTemplate() {
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-3">{timeRender()}</div>

      <div className="col-span-9">{eventsRender()}</div>
    </div>
  );
}

export default function Timesheet() {
  return (
    <div className="w-full">
      <div className="mx-auto w-full rounded-2xl bg-white ">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`${
                  open ? "" : ""
                } flex flex-row justify-between w-full border shadow border-black-100 rounded-2xl p-2 text-xs items-center  transition-all`}
              >
                <div className="flex flex-row font-medium space-x-3 justify-center items-center">
                  <ClockIcon className="w-6 h-6" />
                  <span>Timesheet</span>
                </div>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform text-primary" : ""
                  } h-5 w-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="p-4 h-[40vh] overflow-auto gap-2 text-xs text-black-900 dark:text-black-500">
                <div>{renderTemplate()}</div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
