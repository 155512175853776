import React from "react";
import { useEffect, useState } from "react";
import customAxios from "../../components/AxiosInterceptor/AxiosInterceptor";
import { useNavigate } from "react-router-dom";

function SplashScreen() {
  let navigate = useNavigate();
  const [loop, setLoop] = useState(true);

  useEffect(() => {
    let timer;
    console.log("waht s");
    clearTimeout(timer);
    let token = localStorage.getItem("token");
    if (token == null) {
      navigate("/auth/register");
    } else {
      timer = setTimeout(() => {
        customAxios.get("/app/init").then((res) => {
          console.log("what");
          navigate("/home");
        });
      }, 2000);
    }
  }, []);

  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <img
        class="mx-auto h-20 w-auto animate-pulse"
        src="https://tailwindui.com/img/logos/mark.svg?color=white"
        alt="Your Company"
      />
    </div>
  );
}

export default SplashScreen;
