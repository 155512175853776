import React from "react";

function Heading(props) {
  return (
    <div
      className={`text-2xl z-50 capitalize flex flex-row font-medium space-x-2  ${
        props.toggle ? "text-primary " : "text-white dark:text-black-100"
      } `}
    >
      {props.heading}
    </div>
  );
}

export default Heading;
