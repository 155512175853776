import React, { useState, useEffect, useRef } from "react";
import RootLayout from "../../components/Layout/RootLayout";
import { Link } from "react-router-dom";
import Fuse from "fuse.js";
import attendance from "../../assets/images/attendance.png";
import leaves from "../../assets/images/leave.png";
import overtime from "../../assets/images/overtime.png";
import payslips from "../../assets/images/payslips.png";
import advance from "../../assets/images/advance.png";
import tasksheet from "../../assets/images/task-sheet.png";
import shifts from "../../assets/images/shifts.png";
import holidays from "../../assets/images/holidays.png";
import complaints from "../../assets/images/complaints.png";
import releases from "../../assets/images/releases.png";
import adjustments from "../../assets/images/adjustments.png";
import assets from "../../assets/images/assets.png";
import team from "../../assets/images/team.png";
import certification from "../../assets/images/certification.png";
import reports from "../../assets/images/reports.png";

const services = [
  {
    name: "My Attendance",
    route: "/services/attendance",
    icon: attendance,
    description: "Manage your attendance and see daily logs",
  },
  {
    name: "My Leaves",
    route: "/services/leaves",
    icon: leaves,
    description: "Apply and Manage your leaves",
  },
  {
    name: "My Pay Slips",
    route: "/services/paySlips",
    icon: payslips,
    description: "View your pay slips",
  },

  // {
  //   name: "My Task Sheet",
  //   route: "/services/overtime",
  //   icon: tasksheet,
  //   description: "Manage your timesheet and tasks",
  // },
  {
    name: "My Assets",
    route: "/services/assets",
    icon: assets,
    description: "Manage Assets",
  },
  {
    name: "Issues and Request",
    route: "/services/my-complaints",
    icon: complaints,
    description: "Submit and Manage Issues and Requests",
  },
  {
    name: "My Shifts",
    route: "/services/shifts",
    icon: shifts,
    description: "Manage and view shifts",
  },

  {
    name: "My Holidays",
    route: "/services/holidays",
    icon: holidays,
    description: "View Holidays",
  },

  // Coming soon flagged
  {
    name: "My Advance",
    route: "/services/overtime",
    icon: advance,
    description: "Apply and Manage your advance",
    comingSoon: true,
  },
  {
    name: "Training & Certification",
    route: "/services/holidays",
    icon: certification,
    description: "View Holidays",
    comingSoon: true,
  },
  {
    name: "My Overtime",
    route: "/services/overtime",
    icon: overtime,
    description: "Apply and Manage your overtime",
    comingSoon: true,
  },
  {
    name: "Visual Reports",
    route: "/services/holidays",
    icon: reports,
    description: "View Holidays",
    comingSoon: true,
  },
  {
    name: "My Team",
    route: "/services/holidays",
    icon: team,
    description: "View Team",
    comingSoon: true,
  },
  {
    name: "My Releases",
    route: "/services/releases",
    icon: releases,
    description: "Submit and Manage releases",
    comingSoon: true,
  },
  {
    name: "My Adjustments",
    route: "/services/adjustments",
    icon: adjustments,
    description: "Submit and Manage Adjustments",
    comingSoon: true,
  },
];

// FUse Search Options
const options = {
  isCaseSensitive: false,
  includeScore: false,
  shouldSort: true,
  includeMatches: true,
  findAllMatches: false,
  minMatchCharLength: 4,
  location: 0,
  threshold: 0.6,
  distance: 100,
  useExtendedSearch: false,
  ignoreLocation: false,
  ignoreFieldNorm: false,
  fieldNormWeight: 1,
  keys: ["name", "description"],
};

function Services() {
  const [loading, setLoading] = useState(true);
  const [searched, setSearched] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const fuse = new Fuse(services, options);
  function searchWithFuse(query) {
    if (!query) {
      setSearched(false);
      setSearchResults(null);
    } else {
      const results = fuse.search(query).map((result) => result.item);
      setSearchResults(results);
      setSearched(true);
    }
  }
  return (
    <>
      <RootLayout>
        <div className="absolute top-0 w-[100vw] z-10 h-[20vh] bg-primary" />
        <div className=" space-y-5 z-50">
          {/* Search Bar */}
          <div className="px-5 sticky z-50 top-10">
            <div className="flex flex-row space-x-4">
              <input
                style={{ borderRadius: 5 }}
                type={"search"}
                onChange={(e) => searchWithFuse(e.target.value)}
                placeholder="Search for a Service"
                className="p-2 dark:bg-black-800 text-black-900 dark:text-black-100 px-4 text-xs border w-full border-black-300 dark:border-black-900  focus:outline-none"
              />
              <button className="bg-primary text-white text-xs px-4 py-1 rounded-xl">
                Search
              </button>
            </div>
          </div>

          {/* All Services */}

          <div className="grid grid-cols-2 gap-3 pt-3 px-5 max-h-[76vh] overflow-auto bg-black-100 dark:bg-black-900 pb-10">
            {searched ? (
              <>
                {searchResults?.map((service, index) => (
                  <Link
                    to={service.route}
                    className="dark:bg-black-800 bg-white  p-4 flex flex-col justify-start items-start space-y-3 rounded-xl shadow-md border border-black-200 dark:border-black-800"
                    key={index}
                  >
                    <div className="flex  justify-center items-center ">
                      <img src={service.icon} className="w-10 h-10" />
                    </div>

                    <div className="space-y-1">
                      <p className="text-black-900 dark:text-white text-xs font-medium">
                        {service.name}
                      </p>
                      <p
                        className="text-black-500 dark:text-black-600 text-xs "
                        style={{ fontSize: 10 }}
                      >
                        {service?.description}
                      </p>
                    </div>
                  </Link>
                ))}
              </>
            ) : (
              <>
                {services?.map((service, index) => (
                  <Link
                    to={!service.comingSoon ? service.route : ""}
                    className={`dark:bg-black-800 ${
                      service.comingSoon ? "bg-black-100" : "bg-white shadow-md"
                    }  p-4 flex flex-col justify-start items-start space-y-3 rounded-xl  border border-black-200 dark:border-black-800`}
                    key={index}
                  >
                    <div className="flex  justify-center items-center ">
                      <img src={service.icon} className="w-10 h-10" />
                    </div>

                    <div className="space-y-1">
                      <p className="text-black-900 dark:text-white text-xs font-medium">
                        {service.name}
                      </p>
                      <p
                        className="text-black-500 dark:text-black-600 text-xs "
                        style={{ fontSize: 10 }}
                      >
                        {service?.description}
                      </p>
                    </div>
                  </Link>
                ))}
              </>
            )}
          </div>
        </div>
      </RootLayout>
    </>
  );
}

export default Services;
