import React  from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, XIcon} from '@heroicons/react/solid';
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Fragment } from "react";
import { Transition } from "@headlessui/react";

function Complaint({object})
{
    const [isResolveConfirmatonOpen, setIsResolveConfirmatonOpen] = useState(false);
    function closeResolveConfirmation () {
        setIsResolveConfirmatonOpen(false);
    }
    function openResolveConfirmation () {
        setIsResolveConfirmatonOpen(true);
    }

    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    function openDeleteConfirmation () {
        setIsDeleteConfirmationOpen(true);
    }
    function closeDeleteConfirmation () {
        setIsDeleteConfirmationOpen(false);
    }

    let buttonTime = "";
    let postedDateTime = `${object.postDate} at ${object.postTime}`;
    let respondedDateTime = `${object.respondedDate} at ${object.respondedTime}`;
    let resolvedDateTime = "";
    if(object.status === "pending")
    {
        postedDateTime = `${object.postDate} at ${object.postTime}`;
        buttonTime = object.postTime;
    }
    else if (object.status === "responded")
    {
        respondedDateTime = `${object.respondedDate} at ${object.respondedTime}`;

        buttonTime = object.respondedTime;
    }
    else
    {
        resolvedDateTime = `${object.resolvedDate} at ${object.resolvedTime}`;
        buttonTime = object.resolvedTime;
    }
    return (
        <>
            <Disclosure>
                {({ open }) => (
                <>
                    <Disclosure.Button className={
                        open ?
                        "rounded-t-md sticky top-0 border-white border-2 items-center flex justify-between text-white bg-primary w-[90vw] h-[5vh] mt-5 m-auto shadow-lg p-2"
                        :
                        "rounded-t-md items-center flex justify-between bg-white w-[90vw] h-[5vh] mt-5 m-auto shadow-lg p-2"
                    }>
                        <div className="max-w-[75%]">
                            <h1 className=" font-semibold whitespace-nowrap overflow-hidden text-ellipsis text-xs">{object.title}</h1>
                        </div>
                        <div className="flex justify-end">
                            <div className="w-fit">
                                <h1 className="text-primary font-light text-xs mr-1">
                                    {
                                        open ?
                                        ""
                                        :
                                        buttonTime
                                    }
                                </h1>
                            </div>
                            <ChevronDownIcon
                                className={`${
                                    open ? 
                                    'rotate-180 transform' 
                                    : 
                                    'text-primary'
                                }  h-5 w-5 `}
                            />
                        </div>
                    </Disclosure.Button>
                    <Disclosure.Panel className="pt-5 border-2 border-white border-t-[1px] items-center block bg-white w-[90vw] h-fit mt-0 m-auto rounded-b-md shadow-lg p-2">
                        <table className="h-fit text-xs w-full text-left">
                            <tr className="text-left">
                                <th className="text-xs  align-top pb-2 text-black-900 font-semibold ">Description: </th>
                                <td className=" pl-5 pb-2"><p className="text-black-900 font-semibold ">{postedDateTime}</p>{object.description}</td>
                            </tr>
                            <tr className="text-left border-t border-black-200">
                                <th className=" pt-2 text-xs  align-top pb-2 text-black-900 font-semibold ">Documents: </th>
                                <td className=" pt-2 pl-5 pb-2">None</td>
                            </tr>
                            {
                                object.status === "pending" ?
                                <tr></tr>
                                : 
                                <tr className="border-t border-black-200">
                                <th className=" align-top pt-2 text-black-900 font-semibold">Response:</th>
                                <td className=" pl-5 pt-2"><p className="text-black-900 font-semibold">{respondedDateTime}</p>
                                <p className="text-black-900 font-semibold">{object.respondedPerson}</p>{object.response}</td>
                                </tr>
                            }

                        </table>
                        <div className="flex justify-between mt-2">
                            {
                                object.status === "resolved"?
                                <p className="text-xs text-green font-semibold">Resolved on {resolvedDateTime}</p>
                                :
                                <>
                                <button onClick={openResolveConfirmation} className="m-auto h-[4vh] w-[48%] rounded-lg text-xs font-medium bg-primary text-white shadow">
                                    Resolve
                                </button>
                                <button onClick={openDeleteConfirmation} className="m-auto h-[4vh] w-[48%] rounded-lg text-xs font-medium bg-primary text-white shadow">
                                    Delete
                                </button>
                                </>
                            }
                        </div>
                    </Disclosure.Panel> 
                </>
            )}
            </Disclosure>
            {/* Resolve Confirmation */}
            <Transition appear show={isResolveConfirmatonOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeResolveConfirmation}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black-900 bg-opacity-80" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto ">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-100 translate-y-[200px]"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Panel className="w-full h-fit max-w-screen transform overflow-hidden rounded-2xl bg-white dark:bg-black-800 border border-white dark:border-black-600 p-3 text-left align-middle shadow-xl transition-all">
                        <div className="flex flex-row justify-between items-center">
                            <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-black-900 dark:text-white"
                            >
                            Resolve Complaint? 
                            </Dialog.Title>
                            <XIcon
                                onClick={closeResolveConfirmation}
                                className="w-4 h-4 text-black-700 dark:text-white active:text-primary"
                            />
                        </div>
                        <p className="mt-4 mb-4">This cannot be undone.</p>
                        <div className="flex justify-between w-full h-fit">
                            <button className="m-auto h-[5vh] w-[48%] rounded-lg text-xs font-medium bg-primary text-white shadow">Cancel</button>
                            <button className="m-auto h-[5vh] w-[48%] rounded-lg text-xs font-medium bg-primary text-white shadow">Resolve</button>
                        </div>
                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
                </Dialog>
            </Transition>
            {/* Delete Confirmation */}
            <Transition appear show={isDeleteConfirmationOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeDeleteConfirmation}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black-900 bg-opacity-80" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto ">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-100 translate-y-[200px]"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Panel className="w-full h-fit max-w-screen transform overflow-hidden rounded-2xl bg-white dark:bg-black-800 border border-white dark:border-black-600 p-3 text-left align-middle shadow-xl transition-all">
                        <div className="flex flex-row justify-between items-center">
                            <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-black-900 dark:text-white"
                            >
                            Delete Complaint? 
                            </Dialog.Title>
                            <XIcon
                                onClick={closeDeleteConfirmation}
                                className="w-4 h-4 text-black-700 dark:text-white active:text-primary"
                            />
                        </div>
                        <p className="mt-4 mb-4">This cannot be undone.</p>
                        <div className="flex justify-between w-full h-fit">
                            <button className="m-auto h-[5vh] w-[48%] rounded-lg text-xs font-medium bg-primary text-white shadow">Cancel</button>
                            <button className="m-auto h-[5vh] w-[48%] rounded-lg text-xs font-medium bg-primary text-white shadow">Delete</button>
                        </div>
                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default Complaint;