import React from "react";
import { ClockIcon, MenuIcon, ViewListIcon } from "@heroicons/react/outline";
import { CogIcon, CurrencyDollarIcon } from "@heroicons/react/outline";
import { LogoutIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import attendance from "../../assets/images/attendance.png";
import leaves from "../../assets/images/leave.png";
import overtime from "../../assets/images/overtime.png";
import payslips from "../../assets/images/payslips.png";
import SubHeading from "../../ui/SubHeading/SubHeading";

const shortcuts = [
  {
    name: "My Attendance",
    route: "/services/attendance",
    icon: attendance,
  },
  {
    name: "My Leaves",
    route: "/services/leaves",
    icon: leaves,
  },
  {
    name: "My Pay Slips",
    route: "/services/paySlips",
    icon: payslips,
  },
  {
    name: "My Overtime",
    route: "/services/overtime",
    icon: overtime,
  },
];

function Shortcuts() {
  return (
    <div className="flex flex-col space-y-2">
      <div className="flex flex-row justify-between  overflow-auto  max-w-[90vw] space-x-5">
        {shortcuts.map((shortcut, index) => (
          <Link
            to={shortcut.route}
            className="flex flex-col space-y-2 justify-center items-center"
            key={index}
          >
            <div className="flex p-3 shadow-md rounded-full justify-center items-center bg-white dark:bg-black-800 border  border-black-200 dark:border-black-600">
              <img src={shortcut.icon} className="w-10 h-10" />
            </div>

            <p
              className="text-black-900 dark:text-white"
              style={{ fontSize: 10 }}
            >
              {shortcut.name}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Shortcuts;
