import React from "react";
import underConstruction from "../../assets/images/under-construction.png";

function Construction() {
  return (
    <div className="flex flex-col justify-center space-y-5 items-center ">
      <div className="sticky"></div>
      <img src={underConstruction} className="h-40 w-40" />
      <h1 className="text-black-800">Coming Soon</h1>
    </div>
  );
}

export default Construction;
