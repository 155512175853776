import React from "react";

function SubHeading(props) {
  return (
    <div className="text-normal z-40 capitalize font-medium text-black dark:text-white">
      {props.heading}
    </div>
  );
}

export default SubHeading;
