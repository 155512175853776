import React, { useState, useEffect } from "react";
import WebsiteLayout from "../../../components/Layout/Layout";
import FloatingCard from "../../../components/FloatingCard/FloatingCard";
import { Tab } from "@headlessui/react";

const assets = [
  {
    name: "Lenovo Laptop",
    assetTypeId: 1,
    quantity: 1,
    img: "https://westore.mv/wp-content/uploads/2022/07/0LlNGug25AJQMmJcuHd4H1rhQe3qVfU0lOyX9FZq.jpeg",
    description: "",
    deployedDate: new Date(),
  },
  {
    name: "Laser Jet Pro Printer MFP M28w",
    assetTypeId: 1,
    quantity: 1,
    img: "https://westore.mv/wp-content/uploads/2022/08/vj0GgnBNrUQGytmEuyKEoZqHVRRSXUMBscdRWjJ8.jpg",
    description: "",
    deployedDate: new Date(),
  },
  {
    name: "Final Cut Pro",
    assetTypeId: 2,
    quantity: 2,
    img: "https://i.pcmag.com/imagery/reviews/00FaQZAwQoZwxbFpiehSYlN-89.fit_lim.size_1200x630.v1651782801.jpg",
    description: "",
    deployedDate: new Date(),
  },
  {
    name: "Microsoft Azure A2",
    assetTypeId: 3,
    quantity: 2,
    img: "https://media.trustradius.com/product-logos/OP/Ix/PIX9CZUSJUE4.PNG",
    description: "",
    deployedDate: new Date(),
  },
];

const CardData = [
  {
    name: "Total items",
    amount: assets.length + 1,
  },
  {
    name: "Assigned",
    amount: assets.length - 2,
  },
];

const assetTypes = [
  {
    id: 1,
    name: "Hardware",
  },
  {
    id: 2,
    name: "Software",
  },
  {
    id: 3,
    name: "Licenses",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function MyAssets() {
  const [selectedAssetType, setSelectedAssetType] = useState(0);

  function renderAssets() {
    let filteredAssets = assets.filter(
      (a) => a.assetTypeId == selectedAssetType
    );
    console.log(filteredAssets, "Hello");
    return (
      <div>
        <div className="space-y-2">
          {filteredAssets.map((a, i) => (
            <div
              className="flex flex-row text-sm p-3 justify-start space-x-3 bg-white shadow rounded-xl"
              key={i}
            >
              <img src={a.img} className="h-12 w-12" />
              <div className="flex flex-col justify-around text-left  items-start">
                <p>{a.name}</p>
                {/* Find the asset type */}
                <p className="text-xs text-black-400">
                  {assetTypes.find((at) => at.id === a.assetTypeId)?.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <WebsiteLayout>
      <div className="absolute top-0 w-[100vw] z-10 h-[13vh] bg-primary" />
      <div className="fixed top-0 w-[100vw] z-10 h-[13vh] bg-primary" />
      <FloatingCard data={CardData} />
      <div className="fixed z-5 px-5 top-28 bg-black-100  h-[85vh] overflow-auto w-full">
        <Tab.Group>
          <Tab.List className="flex space-x-1 p-1 mt-6 mb-3">
            <Tab
              onClick={() => setSelectedAssetType(0)}
              className={({ selected }) =>
                classNames(
                  "w-full border-b-2 py-2 text-sm leading-5 ",
                  "focus:ring-0  focus:outline-none  font-medium",
                  selected
                    ? " text-primary border-b-primary "
                    : "text-black-400  border-b-transparent"
                )
              }
            >
              All
            </Tab>
            {assetTypes.map((at, i) => (
              <Tab
                key={i}
                onClick={() => setSelectedAssetType(at.id)}
                className={({ selected }) =>
                  classNames(
                    "w-full border-b-2 py-2 text-sm leading-5 ",
                    "focus:ring-0  focus:outline-none  font-medium",
                    selected
                      ? " text-primary border-b-primary "
                      : "text-black-400  border-b-transparent"
                  )
                }
              >
                {at.name}
              </Tab>
            ))}
          </Tab.List>

          <Tab.Panel
            className={classNames("rounded-xl focus:ring-0 focus:outline-none")}
          >
            <div className="space-y-2">
              {assets.map((a, i) => (
                <div
                  className="flex flex-row text-sm p-3 justify-start space-x-3 bg-white shadow rounded-xl"
                  key={i}
                >
                  <img src={a.img} className="h-12 w-12" />
                  <div className="flex flex-col justify-around text-left  items-start">
                    <p>{a.name}</p>
                    {/* Find the asset type */}
                    <p className="text-xs text-black-400">
                      {assetTypes.find((at) => at.id === a.assetTypeId)?.name}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </Tab.Panel>

          {assetTypes.map((at, i) => (
            <Tab.Panel
              key={i}
              className={classNames(
                "rounded-xl focus:ring-0 focus:outline-none"
              )}
            >
              {renderAssets()}
            </Tab.Panel>
          ))}
        </Tab.Group>
      </div>
    </WebsiteLayout>
  );
}

export default MyAssets;
