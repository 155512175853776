import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

// Main routes
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import Services from "../pages/Services/Services";
import TimeSheet from "../pages/Timesheet/TimeSheet";
import Calendar from "../pages/Calendar/Calendar";
import Settings from "../pages/Settings/Settings";

// Service Routes
import MyAttendance from "../pages/Services/MyAttendance/MyAttendance";
import MyLeaves from "../pages/Services/MyLeaves/MyLeaves";
import MySalary from "../pages/Services/MySalary/MySalary";
import MyProfile from "../pages/Services/MyProfile/MyProfile";
import SplashScreen from "../pages/SplashScreen/SplashScreen";
import NotFound from "../pages/NotFound/NotFound";
import Myshifts from "../pages/Services/MyShifts/Myshifts";
import MyHolidays from "../pages/Services/MyHolidays/MyHolidays";
import MyAssets from "../pages/Services/MyAssets/MyAssets";
import MyComplaints from "../pages/Services/MyComplaints/MyComplaints";
import MyTeam from "../pages/Services/MyTeam/MyTeam";
import Register from "../pages/Registration/Register/Register";

function PageRoutes() {
  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/services/my-complaints"
          element={<MyComplaints />}
        />
        <Route exact path="/" element={<SplashScreen />} />
        <Route exact path="/auth/register" element={<Register />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/timesheet" element={<TimeSheet />} />
        <Route exact path="/calendar" element={<Calendar />} />
        <Route exact path="/settings" element={<Settings />} />
        <Route exact path="/home" element={<Home />} />
        <Route
          exact
          path="/services/attendance"
          element={<MyAttendance />}
          children={true}
        />
        <Route
          exact
          path="/services/shifts"
          element={<Myshifts />}
          children={true}
        />
        <Route
          exact
          path="/services/holidays"
          element={<MyHolidays />}
          children={true}
        />
        <Route
          exact
          path="/services/assets"
          element={<MyAssets />}
          children={true}
        />
        <Route
          exact
          path="/services/team"
          element={<MyTeam />}
          children={true}
        />
        <Route exact path="/services/leaves" element={<MyLeaves />} />
        <Route exact path="/services/paySlips" element={<MySalary />} />
        <Route exact path="/profile" element={<MyProfile />} />
        <Route exact path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default PageRoutes;
