import React, { useState, useEffect } from "react";
import RootLayout from "../../components/Layout/RootLayout";
import Calendar from "../../components/Calendar/Calendar";
import Attendance from "../../components/Attendance/Attendance";
import Timesheet from "../../components/Timesheet/Timesheet";
import Leaves from "../../components/Leaves/Leaves";
import PaySlips from "../../components/Payslips/PaySlips";
import {
  format,
  differenceInMinutes,
  formatISO,
  parseISO,
  isSameMonth,
} from "date-fns";
import moment from "moment";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon, ClockIcon } from "@heroicons/react/outline";
import customAxios from "../../components/AxiosInterceptor/AxiosInterceptor";
import File from "../../assets/images/file.png";

function Services() {
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState([]);
  const [selectedDateData, setSelectedDateData] = useState([]);

  const [time, setTime] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [attendance, setAttendance] = useState();

  useEffect(() => {
    customAxios
      .get("/selfService/AttendanceLog")
      .then((res) => console.log(res));
  }, []);

  function renderSelectedDateAttedance() {
    let checkIn = moment(selectedDateData[0]?.checkIn, "h:mm:ss").format(
      "hh:mm a"
    );
    let checkOut = moment(selectedDateData[0]?.checkOut, "h:mm:ss").format(
      "hh:mm a"
    );
    let startTime = moment(selectedDateData[0]?.start, "h:mm:ss").format(
      "hh:mm a"
    );
    let endTime = moment(selectedDateData[0]?.end, "h:mm:ss").format("hh:mm a");

    // Timestamps
    const checkInTS = `${moment(selectedDate).format("yyyy-MM-DD")}T${moment(
      selectedDateData[0]?.checkIn,
      "h:mm A"
    ).format("HH:mm:ss")}`;
    const startTS = `${moment(selectedDate).format("yyyy-MM-DD")}T${moment(
      selectedDateData[0]?.start,
      "h:mm A"
    ).format("HH:mm:ss")}`;
    const endTS = `${moment(selectedDate).format("yyyy-MM-DD")}T${moment(
      selectedDateData[0]?.end,
      "h:mm A"
    ).format("HH:mm:ss")}`;
    const checkOutTS = `${moment(selectedDate).format("yyyy-MM-DD")}T${moment(
      selectedDateData[0]?.checkOut,
      "h:mm A"
    ).format("HH:mm:ss")}`;

    function calculateOvertime() {
      const result = differenceInMinutes(parseISO(checkOutTS), parseISO(endTS));
      return result > 0 ? result + " minutes" : "N/A";
    }
    function calculateEarly() {
      const result = differenceInMinutes(
        parseISO(checkInTS),
        parseISO(startTS)
      );
      return result > 0 ? result + "minutes" : "N/A";
    }
    function calculateEarlyDeparture() {
      const result = differenceInMinutes(parseISO(endTS), parseISO(checkOutTS));
      return result > 0 ? result + "minutes" : "N/A";
    }
    return (
      <div className="grid grid-cols-12 gap-3 bg-white shadow p-5 rounded-xl border border-black-200">
        <div className="col-span-5">
          <ul className="flex flex-col text-black-500 space-y-1">
            <li>Check in:</li>
            <li>Check out:</li>
            <li>Early:</li>
            <li>Overtime:</li>
            <li>Early Departure:</li>
          </ul>
        </div>
        <div className="col-span-7">
          <ul className="flex flex-col space-y-1 uppercase  font-medium">
            <li>{checkIn}</li>
            <li>{checkOut}</li>
            <li>{calculateEarly()}</li>
            <li>{calculateOvertime()}</li>
            <li>{calculateEarlyDeparture()}</li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <>
      <RootLayout>
        <div className="absolute top-24 w-[100vw] z-10 h-[90vh] bg-black-100" />
        <div className="px-5 sticky z-50 top-10">
          <Calendar
            setChildSelectedDate={setSelectedDate}
            setChildSelectedDateData={setSelectedDateData}
          />
          {/* Disclosure Panels */}
          <div className="flex  flex-col space-y-1 pt-2">
            <Timesheet />
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={`${
                      open ? "bg-primary text-white" : ""
                    } flex flex-row justify-between font-medium w-full  border border-black-100 shadow rounded-xl p-2 text-xs items-center  transition-all`}
                  >
                    <div className="flex flex-row space-x-3 justify-center items-center">
                      <ClockIcon className="w-6 h-6" />
                      <span>Attendance</span>
                    </div>
                    <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-purple-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="">
                    {selectedDateData.length >= 1 ? (
                      <>{renderSelectedDateAttedance()}</>
                    ) : (
                      <div className="flex flex-col space-y-3 justify-center items-center bg-white shadow rounded-xl border border-black-200">
                        <img src={File} className="w-20 h-20" />
                        <p>No Data</p>
                      </div>
                    )}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            {/* <Leaves /> */}
          </div>
        </div>
      </RootLayout>
    </>
  );
}

export default Services;
