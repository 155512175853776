import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon, ClockIcon } from "@heroicons/react/outline";
import { DownloadIcon } from "@heroicons/react/solid";

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "MVR",
});

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const dummySalary = {
  basicPay: 9500,
  travelAllowance: +1000,
  jobExperienceAllowance: +8000,
  technicalCertificationAllowance: +2500,
  professionalAllowance: +3000,
  attendanceBonus: +500,
  pension: -500,
  lateFine: -300,
};

export default function PaySlips() {
  return (
    <div className="w-full">
      <div className="mx-auto w-full rounded-2xl bg-white">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`${
                  open ? "bg-primary text-white" : ""
                } flex flex-row justify-between font-medium w-full  border border-black-100 shadow rounded-xl p-2 text-xs items-center  transition-all`}
              >
                <div className="flex flex-row space-x-3 justify-center items-center">
                  <ClockIcon className="w-6 h-6" />
                  <span>Monthly Pay Slip</span>
                </div>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-purple-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="p-4 grid grid-cols-12 shadow-md rounded-b-2xl  gap-2 text-xs text-black-900 dark:text-black-500">
                <div className=" z-30 col-span-12 bg-white dark:bg-black-800 flex flex-col space-y-4 rounded-xl w-full ">
                  {/* body */}
                  <div className="text-xs text-black-900 dark:text-white flex flex-col space-y-5">
                    <div className="flex justify-between">
                      <h1>Salary</h1>
                      <p className="font-medium text-primary">
                        {formatter.format(dummySalary.basicPay)}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <h1>Travel Allowance</h1>
                      <p
                        className={`font-medium ${
                          dummySalary.travelAllowance < 0
                            ? "text-danger"
                            : "text-success"
                        }`}
                      >
                        {dummySalary.travelAllowance > 0 ? "+ " : ""}
                        {formatter.format(dummySalary.travelAllowance)}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <h1>Job Experience Allowance</h1>
                      <p
                        className={`font-medium ${
                          dummySalary.jobExperienceAllowance < 0
                            ? "text-danger"
                            : "text-success"
                        }`}
                      >
                        {dummySalary.jobExperienceAllowance > 0 ? "+ " : ""}
                        {formatter.format(dummySalary.jobExperienceAllowance)}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <h1>Technical Certification Allowance</h1>
                      <p
                        className={`font-medium ${
                          dummySalary.technicalCertificationAllowance < 0
                            ? "text-danger"
                            : "text-success"
                        }`}
                      >
                        {dummySalary.technicalCertificationAllowance > 0
                          ? "+ "
                          : ""}
                        {formatter.format(
                          dummySalary.technicalCertificationAllowance
                        )}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <h1>Professional Allowance</h1>
                      <p
                        className={`font-medium ${
                          dummySalary.professionalAllowance < 0
                            ? "text-danger"
                            : "text-success"
                        }`}
                      >
                        {dummySalary.professionalAllowance > 0 ? "+ " : ""}
                        {formatter.format(dummySalary.professionalAllowance)}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <h1>Attendance Bonus</h1>
                      <p
                        className={`font-medium ${
                          dummySalary.attendanceBonus < 0
                            ? "text-danger"
                            : "text-success"
                        }`}
                      >
                        {dummySalary.attendanceBonus > 0 ? "+ " : ""}
                        {formatter.format(dummySalary.attendanceBonus)}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <h1>Pension</h1>
                      <p
                        className={`font-medium ${
                          dummySalary.pension < 0
                            ? "text-danger"
                            : "text-success"
                        }`}
                      >
                        {dummySalary.pension > 0 ? "+ " : ""}
                        {formatter.format(dummySalary.pension)}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <h1>Late Fine</h1>
                      <p
                        className={`font-medium ${
                          dummySalary.lateFine < 0
                            ? "text-danger"
                            : "text-success"
                        }`}
                      >
                        {dummySalary.lateFine > 0 ? "+ " : ""}
                        {formatter.format(dummySalary.lateFine)}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center flex-row font-medium justify-between pt-2 border-t border-t-black-300  dark:border-black text-black dark:text-white text-left text-sm">
                    <h1>Total</h1>
                    <h1 className="  font-medium">
                      {formatter.format(
                        dummySalary.basicPay +
                          dummySalary.travelAllowance +
                          dummySalary.jobExperienceAllowance +
                          dummySalary.technicalCertificationAllowance +
                          dummySalary.professionalAllowance +
                          dummySalary.attendanceBonus +
                          dummySalary.pension +
                          dummySalary.lateFine
                      )}
                    </h1>
                  </div>
                  {/* Download Pay Slip */}
                  <button className="bg-primary p-2 mt-4 text-xs rounded-xl flex text-white dark:text-black-200 justify-center items-center flex-row shadow-md">
                    <DownloadIcon className="w-5 h-5 mr-3  " />
                    Download Payslip
                  </button>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
