import Heading from "../../ui/Heading/Heading";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const menuItems = [
  {
    name: "Home",
    route: "/home",
  },
  {
    name: "Services",
    route: "/services",
  },
  {
    name: "Calendar",
    route: "/calendar",
  },

  {
    name: "Time Sheet",
    route: "/timesheet",
  },

  {
    name: "Settings",
    route: "/settings",
  },
];

function Header() {
  const [open, setOpen] = useState(false);

  const [navToggle, setNavToggle] = useState(false);

  const { pathname } = useLocation();

  const pathnames = pathname.split("/").filter(Boolean);

  const changeNavbarColor = () => {
    if (window.scrollY >= 20) {
      setNavToggle(true);
    } else {
      setNavToggle(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <div style={{ zIndex: 50 }} className={`fixed top-0 w-full transparent`}>
      <div className="flex flex-row justify-between items-center px-5 pb-3 ">
        <div className="flex flex-row justify-center items-center">
          <Heading
            heading={pathnames.map((name, index) => {
              const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
              const isLast = index === pathnames.length - 1;
              return isLast ? (
                <p key={name}>{name}</p>
              ) : (
                <div className="flex f justify-center items-center">
                  <div>
                    {!isLast ? (
                      <Link to={routeTo}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5L8.25 12l7.5-7.5"
                          />
                        </svg>
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                  <Link
                    className={`${isLast ? "flex" : "hidden"}`}
                    key={name}
                    to={routeTo}
                  >
                    {name}
                  </Link>
                </div>
              );
            })}
            toggle={navToggle}
          />
        </div>
        <div className="flex justify-end items-center space-x-3">
          <div
            className={`flex flex-col items-end justify-end  ${
              navToggle
                ? "text-black-900 dark:text-black-100"
                : "text-black-100 dark:text-black-900"
            }  `}
          >
            <p style={{ fontSize: 10 }} className="font-medium">
              {moment().format("dddd")}
            </p>
            <p className="text-xs ">{moment().format("MMM Do YYYY")}</p>
          </div>

          <div
            className={`flex justify-center   ${
              navToggle
                ? "text-black-900 dark:text-black-100"
                : "text-black-100 dark:text-black-900"
            }     items-center`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-8 h-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
