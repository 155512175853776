import React, { useState, useEffect } from "react";
import {
  format,
  addMonths,
  subMonths,
  startOfWeek,
  addDays,
  isSameDay,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  parse,
  getDay,
  getMonth,
  parseISO,
} from "date-fns";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { Dialog } from "@headlessui/react";
import moment from "moment";
import customAxios from "../AxiosInterceptor/AxiosInterceptor";

const legends = [
  {
    name: "present",
    color: "#99cc00",
  },
  {
    name: "absent",
    color: "#ff471a",
  },
  {
    name: "late",
    color: "#ffaa00",
  },
  {
    name: "release",
    color: "#c44dff",
  },
  {
    name: "leave",
    color: "#2596be",
  },
];

function Calendar(props) {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [notAbsentDays, setNotAbsentDays] = useState([]);
  const [show, setShow] = useState(true);

  useEffect(() => {
    setCurrentMonth(props?.selectedMonth ? props.selectedMonth : new Date());
    renderDays(props?.selectedMonth);
    customAxios.get("/selfService/AttendanceLog").then((res) => {
      console.log(res);
      setNotAbsentDays(res);
    });
    getSelectedDateData(currentMonth);
  }, [props.selectedMonth]);

  function renderHeader() {
    let dateFormat = "MMMM yyyy";
    let selectedDateFormat = "iiii, dd MMMM yyyy";

    return (
      <div className="flex space-x-5 flex-row justify-between items-center">
        {props.dateChangeable === false ? (
          <>
            <span className="font-medium">
              {format(props.selectedMonth, "MMMM YYY")}
            </span>
          </>
        ) : (
          <>
            {show ? (
              <ChevronLeftIcon
                onClick={() => prevMonth()}
                className="w-5 h-5"
              />
            ) : (
              ""
            )}
            <span className="font-medium">
              {show
                ? format(currentMonth, dateFormat)
                : format(selectedDate, selectedDateFormat)}
            </span>
            {show ? (
              <ChevronRightIcon
                onClick={() => nextMonth()}
                className="w-5 h-5"
              />
            ) : (
              <ChevronDownIcon
                className="w-5 h-5"
                onClick={() => setShow(true)}
              />
            )}
          </>
        )}
      </div>
    );
  }

  function renderDays(selectedMonth) {
    const dateFormat = "iii";
    const days = [];
    let startDate = startOfWeek(currentMonth);
    for (let i = 0; i < 7; i++) {
      let day = format(addDays(startDate, i), dateFormat);
      days.push(
        <div
          className="text-xs flex items-center justify-center w-full"
          key={i}
        >
          {day == "Fri" || day == "Sat" ? (
            <span className="text-primary">{day}</span>
          ) : (
            <span>{day}</span>
          )}
        </div>
      );
    }
    return (
      <div className="flex mt-4 flex-row w-fulls justify-between space-x-1 mb-2 text-center ">
        {days}
      </div>
    );
  }

  function RenderCells() {
    const holidays = [new Date()];
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const dayFormat = "iii";
    const dateFormat = "dd";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            onClick={
              isSameMonth(day, monthStart) ? () => onDateClick(cloneDay) : ""
            }
            className={`flex items-center justify-center ${
              format(day, dayFormat) == "Fri" || format(day, dayFormat) == "Sat"
                ? "text-primary"
                : ""
            } ${
              !isSameMonth(day, monthStart)
                ? "text-black-400 "
                : holidays.map((holiday, index) =>
                    isSameDay(holiday, day) ? "text-primary" : ""
                  )
            } `}
            key={day}
          >
            <span
              className={`h-8 w-8 flex justify-center flex-col items-center ${
                isSameDay(selectedDate, cloneDay)
                  ? "border-primary border-2 rounded-full text-primary font-medium "
                  : " "
              } ${
                !isSameDay(day, new Date())
                  ? "bg-white"
                  : "bg-primary text-white rounded-full"
              }`}
            >
              <p>{formattedDate}</p>

              {isSameMonth(day, monthStart) ? renderDayStatus(day) : <p></p>}
            </span>
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className={`grid grid-cols-7 `} key={day}>
          {days}
        </div>
      );
      days = [];
    }

    return <div className="flex flex-col space-y-2">{rows}</div>;
  }

  function renderDayStatus(day) {
    function findStatus() {
      let color;
      let formattedDay = format(day, "yyyy-MM-dd'T'HH:mm:ss");

      if (notAbsentDays.find((nad) => nad.date == formattedDay)) {
        color = "#99cc00";
      }

      let dayName = getDay(day);
      if (dayName === "Friday") {
        color = null;
      }
      return color ? color : "#ccc";
    }

    return (
      <div
        style={{
          height: 3,
          width: 8,
          marginTop: 1.5,
          borderRadius: 15,
          backgroundColor: findStatus(),
        }}
      ></div>
    );
  }

  function getSelectedDateData(day) {
    customAxios
      .get(
        `/selfService/AttendanceLog?where=Date='${format(day, "MM-dd-yyyy")}'`
      )
      .then((res) => {
        setNotAbsentDays(res);
        props?.setChildSelectedDateData(res);
      });
  }

  function renderLegend() {
    return (
      <div style={{ fontSize: 10 }} className="flex flex-row space-x-3 mt-3">
        {legends.map((legend, index) => (
          <div className="flex flex-row capitalize justify-center items-center space-x-2">
            <div
              style={{
                height: 3,
                width: 8,
                marginTop: 1.5,
                backgroundColor: legend.color,
              }}
              className={`rounded-full `}
            ></div>
            <p>{legend.name}</p>
          </div>
        ))}
      </div>
    );
  }

  function onDateClick(day) {
    props?.setChildSelectedDate(day);
    setSelectedDate(day);
    getSelectedDateData(day);
    renderDayStatus(day);
  }

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };
  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  return (
    <div className="sticky top-10 bg-white border border-black-200 shadow-md rounded-xl p-5 ">
      <div>{renderHeader()}</div>
      {show && (
        <div className=" ">
          <div>{renderDays()}</div>

          <div className="text-xs mt-3">{RenderCells()}</div>
          <div>{renderLegend()}</div>
        </div>
      )}
    </div>
  );
}

export default Calendar;
