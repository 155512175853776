import React from "react";

function Card({ children, props }) {
  return (
    <div
      className={`shadow  bg-white dark:bg-black-800  flex flex-col space-y-4 rounded-xl w-full p-4 border border-black-200 dark:border-black-700`}
    >
      {children}
    </div>
  );
}

export default Card;
