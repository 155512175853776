import React, { useState, useEffect, Fragment } from "react";
import WebsiteLayout from "../../../components/Layout/Layout";
import moment from "moment/moment";
import { Listbox, Transition, Disclosure, Tab } from "@headlessui/react";
import { CheckCircleIcon, ChevronDownIcon } from "@heroicons/react/solid";
import customAxios from "../../../components/AxiosInterceptor/AxiosInterceptor";
import FloatingCard from "../../../components/FloatingCard/FloatingCard";
import Calendar from "../../../components/Calendar/Calendar";
import {
  format,
  differenceInMinutes,
  formatISO,
  parseISO,
  isSameMonth,
} from "date-fns";
import SubHeading from "../../../ui/SubHeading/SubHeading";
import File from "../../../assets/images/file.png";

const year = [2022];

const months = [
  new Date("2022-01-01T09:00:00"),

  new Date("2022-02-01T09:00:00"),

  new Date("2022-03-01T09:00:00"),

  new Date("2022-04-01T09:00:00"),

  new Date("2022-05-01T09:00:00"),

  new Date("2022-06-01T09:00:00"),

  new Date("2022-07-01T09:00:00"),

  new Date("2022-08-01T09:00:00"),

  new Date("2022-09-01T09:00:00"),

  new Date("2022-10-01T09:00:00"),

  new Date("2022-11-01T09:00:00"),

  new Date("2022-12-01T09:00:00"),
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function MyAttendance() {
  const [time, setTime] = useState();
  const [selectedYear, setSelectedYear] = useState(year[0]);
  const [selectedMonth, setSelectedMonth] = useState(
    months.find((m) => isSameMonth(m, new Date())) ?? new Date()
  );
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDateData, setSelectedDateData] = useState([]);
  const [attendance, setAttendance] = useState();

  useEffect(() => {
    customAxios
      .get("/selfService/AttendanceLog")
      .then((res) => console.log(res));
  }, []);

  const CardData = [
    {
      name: "Present",
      amount: 2,
    },
    {
      name: "Late",
      amount: 5,
    },
    {
      name: "Absent",
      amount: 10,
    },
  ];

  function renderSelectedDateAttedance() {
    let checkIn = moment(selectedDateData[0]?.checkIn, "h:mm:ss").format(
      "hh:mm a"
    );
    let checkOut = moment(selectedDateData[0]?.checkOut, "h:mm:ss").format(
      "hh:mm a"
    );
    let startTime = moment(selectedDateData[0]?.start, "h:mm:ss").format(
      "hh:mm a"
    );
    let endTime = moment(selectedDateData[0]?.end, "h:mm:ss").format("hh:mm a");

    // Timestamps
    const checkInTS = `${moment(selectedDate).format("yyyy-MM-DD")}T${moment(
      selectedDateData[0]?.checkIn,
      "h:mm A"
    ).format("HH:mm:ss")}`;
    const startTS = `${moment(selectedDate).format("yyyy-MM-DD")}T${moment(
      selectedDateData[0]?.start,
      "h:mm A"
    ).format("HH:mm:ss")}`;
    const endTS = `${moment(selectedDate).format("yyyy-MM-DD")}T${moment(
      selectedDateData[0]?.end,
      "h:mm A"
    ).format("HH:mm:ss")}`;
    const checkOutTS = `${moment(selectedDate).format("yyyy-MM-DD")}T${moment(
      selectedDateData[0]?.checkOut,
      "h:mm A"
    ).format("HH:mm:ss")}`;

    function calculateOvertime() {
      const result = differenceInMinutes(parseISO(checkOutTS), parseISO(endTS));
      return result > 0 ? result + " minutes" : "N/A";
    }
    function calculateEarly() {
      const result = differenceInMinutes(
        parseISO(checkInTS),
        parseISO(startTS)
      );
      return result > 0 ? result + "minutes" : "N/A";
    }
    function calculateEarlyDeparture() {
      const result = differenceInMinutes(parseISO(endTS), parseISO(checkOutTS));
      return result > 0 ? result + "minutes" : "N/A";
    }

    return (
      <div className="grid grid-cols-12 gap-3 bg-white shadow p-5 rounded-xl border border-black-200">
        <div className="col-span-5">
          <ul className="flex flex-col text-black-500 space-y-1">
            <li>Check in:</li>
            <li>Check out:</li>
            <li>Early:</li>
            <li>Overtime:</li>
            <li>Early Departure:</li>
          </ul>
        </div>
        <div className="col-span-7">
          <ul className="flex flex-col space-y-1 uppercase  font-medium">
            <li>{checkIn}</li>
            <li>{checkOut}</li>
            <li>{calculateEarly()}</li>
            <li>{calculateOvertime()}</li>
            <li>{calculateEarlyDeparture()}</li>
          </ul>
        </div>
      </div>
    );
  }

  function renderMonths() {
    let __selectedMonth = months.find((m) => isSameMonth(m, selectedMonth));

    let __months = months.filter((m) => m !== __selectedMonth);

    return (
      <>
        <Tab
          className={({ selected }) =>
            classNames(
              " rounded-lg  min-w-fit py-1 px-3 text-xs font-medium leading-5 ",
              "ring-white ring-opacity-60 ring-offset-2 ring-offset-orange-400 focus:outline-none focus:ring-2",
              "bg-primaryLight text-white shadow"
            )
          }
        >
          {format(__selectedMonth, "MMMM")}
        </Tab>
        {__months.map((month, index) => (
          <>
            <Tab
              onClick={() => {
                setSelectedMonth(month);
              }}
              key={index}
              className={({ selected }) =>
                classNames(
                  " rounded-lg  min-w-fit py-1 px-3 text-xs font-medium leading-5 ",
                  "ring-white focus:ring-0 focus:outline-none ",

                  "text-primary  "
                )
              }
            >
              {format(month, "MMMM")}
            </Tab>
          </>
        ))}
      </>
    );
  }

  return (
    <WebsiteLayout>
      <div className="absolute top-0 w-[100vw] z-10 h-[17vh] bg-primary" />
      <div className=" relative flex flex-col space-y-3 px-5 mt-3">
        {/* Year and Month Select */}
        <div className="flex z-40  bg-white rounded-xl p-1 flex-row space-x-2 justify-start items-center">
          {/* Year Select */}
          <div className="w-fit">
            <Listbox value={selectedYear} onChange={setSelectedYear}>
              <div className="relative ">
                <Listbox.Button className="relative w-full text-xs cursor-default rounded-lg bg-primary py-1 pl-3 pr-10 text-left  focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                  <span className="block truncate text-white text-sm">
                    {selectedYear}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 z-50 max-h-60 w-full overflow-auto rounded-xl bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                    {year.map((year, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          `relative cursor-default text-xs select-none py-2  px-4 ${
                            active
                              ? "bg-amber-100 font-medium text-amber-900 "
                              : "text-black-900 "
                          }`
                        }
                        value={year}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-normal" : "font-medium"
                              }`}
                            >
                              {year}
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
          {/* Month Select */}
          <Tab.Group>
            <Tab.List className="flex w-full justify-between bg  overflow-x-auto max-w-[90vw] space-x-1   bg-orange-50 ">
              {renderMonths()}
            </Tab.List>
          </Tab.Group>
        </div>
      </div>
      <FloatingCard data={CardData} />
      <div className="h-[78vh] overflow-auto w-screen">
        <div className="px-5 mt-24">
          <Calendar
            dateChangeable={false}
            selectedMonth={selectedMonth}
            setChildSelectedDate={setSelectedDate}
            setChildSelectedDateData={setSelectedDateData}
          />
        </div>
        {/* Selected Dates Attendance */}

        <div className="px-5 text-xs mt-6 flex space-y-3 flex-col">
          <SubHeading
            heading={`Attendance on ${format(selectedDate, "dd MMMM yyyy")}`}
          />
          {selectedDateData.length >= 1 ? (
            <>{renderSelectedDateAttedance()}</>
          ) : (
            <div className="flex flex-col space-y-3 justify-center items-center bg-white shadow p-5 rounded-xl border border-black-200">
              <img src={File} className="w-20 h-20" />
              <p>No Data</p>
            </div>
          )}
        </div>
      </div>
    </WebsiteLayout>
  );
}

export default MyAttendance;
