import React, { useState, useEffect } from "react";
import RootLayout from "../../components/Layout/RootLayout";
import moment from "moment/moment";
import Heading from "../../ui/Heading/Heading";
import { Link } from "react-router-dom";
import Construction from "../../components/Construction/Construction";

function Settings() {
  const [loading, setLoading] = useState(true);
  return (
    <>
      <RootLayout>
        <div className="px-5 space-y-4">
          <Heading heading="Settings" />
          <Construction />
        </div>
      </RootLayout>
    </>
  );
}

export default Settings;
