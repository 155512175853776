import React from "react";
import Card from "../../ui/Card/Card";
import SubHeading from "../../ui/SubHeading/SubHeading";

const events = [
  {
    day: 23,
    month: "dec",
    name: "Due to bad weather condition, staffs will be given 30 minutes attendance ",
    place: "Eches Office",
    time: "A few moments ago",
  },
  {
    day: 10,
    month: "nov",
    name: "The company has won Internation Test Awards",
    place: "Eches Office",
    time: "A few moments ago",
  },
  {
    day: 23,
    month: "dec",
    name: "Congratulate Aminath Nadha for getting promoted as the Head of IT Department",
    place: "Eches Office",
    time: "A few moments dygdu ago",
  },
];

function Announcements() {
  return (
    <div className="flex flex-col space-y-3">
      <div className="flex flex-row justify-between items-baseline px-5">
        <SubHeading heading="Announcements" />
        <button className="dark:text-white text-primary text-xs capitalize">
          view all
        </button>
      </div>
      {/* Event Cards */}
      <div className="max-w-[100vw] mx-5 overflow-auto flex flex-col space-y-2 pb-3">
        {events.map((event, index) => (
          <div key={index} className="flex ">
            <Card>
              <div className="flex flex-row space-x-6 dark:text-black-100 text-black-900 ">
                <div className="flex flex-col justify-center items-center text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8 text-primary"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46"
                    />
                  </svg>
                </div>
                <div className="text-xs">
                  <p className="text-sm font-medium">{event.name}</p>
                  <p style={{ fontSize: 10 }}>{event.time}</p>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Announcements;
