import React, { useState, useEffect } from "react";
import SubHeading from "../../ui/SubHeading/SubHeading";
import customAxios from "../AxiosInterceptor/AxiosInterceptor";
import Fuse from "fuse.js";

// FUse Search Options
const options = {
  isCaseSensitive: false,
  includeScore: true,
  shouldSort: true,
  includeMatches: false,
  findAllMatches: false,
  minMatchCharLength: 5,
  location: 0,
  threshold: 0.6,
  distance: 100,
  useExtendedSearch: false,
  ignoreLocation: false,
  ignoreFieldNorm: false,
  fieldNormWeight: 1,
  keys: ["name"],
};

const searchResults = [
  {
    name: "Mohamed Arusham Hussain",
    employeeId: "E2883",
    designation: "Full-stack Developer",
    department: "IT Development",
    extension: "1779",
    phone: "9993529",
    email: "arusham@echesconsultancy.com",
    image:
      "https://img.freepik.com/free-photo/close-up-young-successful-man-smiling-camera-standing-casual-outfit-against-blue-background_1258-66609.jpg?w=2000",
  },
  {
    name: "Arusham Ibrahim",
    employeeId: "E2833",
    designation: "Developer",
    department: "IT Development",
    extension: "1779",
    phone: "9993529",
    email: "arusham@echesconsultancy.com",
    image:
      "https://img.freepik.com/premium-photo/young-handsome-man-with-beard-isolated-keeping-arms-crossed-frontal-position_1368-132662.jpg?w=2000",
  },
];

function EmployeeDirectory() {
  const [searched, setSearched] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [employees, setEmployees] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const fuse = new Fuse(employees, options);
  function searchWithFuse() {
    if (!searchString) {
      setSearched(false);
      setSearchResults(null);
    } else {
      const results = fuse.search(searchString).map((result) => result.item);
      setSearchResults(results);
      setSearched(true);
    }
  }

  useEffect(() => {
    customAxios.get("/employee").then((res) => {
      console.log(res.items);
      setEmployees(res.items);
    });
    setSearchString("");
  }, []);
  return (
    <div className="flex flex-col space-y-3  justify-start px-4 items-start pb-3">
      <SubHeading heading="Employee Directory" />
      <div className="flex bg-white rounded-xl shadow-md  p-4 border border-black-200 flex-col w-full space-y-2 mt-2 text-sm">
        <div className="flex flex-col space-y-2">
          <input
            type="search"
            placeholder="Search using name"
            onChange={(e) => setSearchString(e.target.value)}
            className="form-control w-full text-xs p-2 focus:outline-none focus:border-black-200 focus:ring-orange-400 rounded-xl border border-black-200 "
          ></input>
          <div className="flex flex-row space-x-2 w-full">
            <button
              onClick={() => searchWithFuse()}
              className=" rounded-xl p-1.5 w-full text-xs bg-primary text-white flex justify-center items-center"
            >
              Search
            </button>
            <button
              onClick={() => setSearched(false) && setSearchString("")}
              className=" rounded-xl p-1.5 w-full border text-xs border-primary bg-orange-50 text-primary flex justify-center items-center"
            >
              Clear
            </button>
          </div>
        </div>
        {searched && (
          <div className="text-xs">
            {searchResults.length >= 1 && (
              <div>
                <h1>
                  <span className="font-medium">{searchResults.length}</span>{" "}
                  matching results found for"
                  {searchString}"
                </h1>
                <div className="flex mt-4 flex-col space-y-2">
                  {searchResults.map((emp, index) => (
                    <div
                      className="flex flex-col space-y-4 justify-between items-center rounded-xl shadow-md p-4 border border-black-200"
                      key={index}
                    >
                      <div className="grid grid-cols-12 gap-x-3">
                        <div className="col-span-5 flex justify-center items-center">
                          <div
                            style={{ backgroundImage: `url(${emp?.avatar})` }}
                            className="col-span-3 rounded-full border-2 border-primary bg-cover bg-center h-24 w-24"
                          ></div>
                        </div>
                        <div className="col-span-7 flex flex-col space-y-1">
                          <p className="text-base font-medium">{emp.name}</p>
                          <p className="">{emp?.empJobs[0]?.employeeId}</p>
                          <p className="text-primary">
                            {emp?.empJobs[0]?.designation?.name}
                          </p>
                        </div>
                      </div>
                      <div className=" gap-x-6 grid grid-cols-12">
                        <div className="col-span-3 text-black-600 flex-col space-y-2 flex justify-start items-start">
                          <p>Phone:</p>
                          <p>Email</p>

                          <p>Department:</p>
                        </div>
                        <div className="col-span-9 ml-2 flex-col space-y-2 flex justify-start items-start">
                          <p>{emp?.empContacts[0]?.mobile}</p>
                          <p>{emp?.empContacts[0]?.email}</p>

                          <p>{emp?.empJobs[0]?.department?.name}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {searchResults.length === 0 && (
              <div>
                <h1>
                  <span className="font-medium">{searchResults.length}</span>{" "}
                  matching results found for"
                  {searchString}"
                </h1>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default EmployeeDirectory;
