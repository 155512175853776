import React, { useState, useEffect } from "react";
import {
  format,
  addMonths,
  subMonths,
  startOfWeek,
  addDays,
  isSameDay,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  isSameMonth,
  parse,
  getDay,
  getMonth,
  parseISO,
} from "date-fns";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { Dialog } from "@headlessui/react";
import moment from "moment";

function ShiftCalendar(props) {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [show, setShow] = useState(true);

  function renderHeader() {
    let dateFormat = "MMMM yyyy";
    let selectedDateFormat = "iiii, dd MMMM yyyy";

    return (
      <div className="flex space-x-5 flex-row justify-between items-center">
        {show ? (
          <ChevronLeftIcon onClick={() => prevMonth()} className="w-5 h-5" />
        ) : (
          ""
        )}
        <span className="font-medium">
          {show
            ? format(currentMonth, dateFormat)
            : format(selectedDate, selectedDateFormat)}
        </span>
        {show ? (
          <ChevronRightIcon onClick={() => nextMonth()} className="w-5 h-5" />
        ) : (
          <ChevronDownIcon className="w-5 h-5" onClick={() => setShow(true)} />
        )}
      </div>
    );
  }

  function renderDays() {
    const dateFormat = "iii";
    const days = [];
    let startDate = startOfWeek(currentMonth);
    for (let i = 0; i < 7; i++) {
      let day = format(addDays(startDate, i), dateFormat);
      days.push(
        <div
          className="text-xs flex items-center justify-center w-full"
          key={i}
        >
          <span>{day}</span>
        </div>
      );
    }
    return (
      <div className="flex mt-4 flex-row w-fulls justify-between space-x-1 mb-2 text-center ">
        {days}
      </div>
    );
  }

  const holidays = [];

  function RenderCells() {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const dayFormat = "iii";
    const dateFormat = "dd";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            onClick={
              isSameMonth(day, monthStart) ? () => onDateClick(cloneDay) : ""
            }
            className={`flex items-center justify-center ${
              !isSameMonth(day, monthStart)
                ? "text-black-100 "
                : holidays.map((holiday, index) =>
                    isSameDay(holiday, day) ? "text-white" : "text-black-100"
                  )
            } `}
            key={day}
          >
            {renderDayShift(formattedDate, cloneDay, day)}
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className={`grid grid-cols-7 `} key={day}>
          {days}
        </div>
      );
      days = [];
    }

    return <div className="flex flex-col space-y-2">{rows}</div>;
  }

  function shiftColorRender(day) {
    const isHoliday = holidays.find((holiday) => isSameDay(holiday, day));
    if (!isHoliday) {
      let shiftType = props?.shifts.find((shift) =>
        isSameDay(day, shift.date)
      )?.shiftType;
      let color = props?.shiftTypes.find((st) => st.id === shiftType)?.color;
      return color ?? "#fff";
    } else {
      return "red";
    }
  }

  function renderDayShift(formattedDate, cloneDay, day) {
    return (
      <div
        className="flex justify-center items-center border-2"
        style={{
          borderSize: 2,
          borderRadius: "100%",
          padding: 2,
          height: 30,
          width: 30,
          backgroundColor: shiftColorRender(day),
          borderColor: shiftColorRender(day),
        }}
      >
        <p>{formattedDate}</p>
      </div>
    );
  }

  function onDateClick(day) {
    setSelectedDate(day);
    setShow(false);
  }

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };
  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  return (
    <div className="sticky top-10 rounded-xl shadow-md bg-white border border-black-200 p-5 ">
      <div>{renderHeader()}</div>
      {show && (
        <div className=" ">
          <div>{renderDays()}</div>
          <div className="text-xs mt-3">{RenderCells()}</div>
        </div>
      )}
    </div>
  );
}

export default ShiftCalendar;
