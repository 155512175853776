import React from "react";
import Card from "../../ui/Card/Card";
import SubHeading from "../../ui/SubHeading/SubHeading";

const events = [
  {
    day: 6,
    month: "OCT",
    name: "Hussain's Birthday",
    place: "Eches Office",
    time: "12:30 PM",
  },
  {
    day: 10,
    month: "OCT",
    name: "Company Anniversary",
    place: "Eches Office",
    time: "12:30 PM",
  },
  {
    day: 23,
    month: "nov",
    name: "Eid Muabaaraiy",
    place: "Eches Office",
    time: "12:30 PM",
  },
];

function Events() {
  return (
    <div className="flex flex-col space-y-3">
      <div className="flex flex-row justify-between items-baseline px-5">
        <SubHeading heading="upcoming events" />
        <button className="dark:text-white text-primary text-xs capitalize">
          view all
        </button>
      </div>
      {/* Event Cards */}
      <div className="min-w-[100vw] overflow-auto flex flex-row space-x-2 pl-5 pb-2">
        {events.map((event, index) => (
          <div className="flex min-w-[40vw] ">
            <Card>
              <div className="flex flex-row space-x-3 text-black-800 dark:text-black-100">
                <div className="flex flex-col justify-center items-center text-center">
                  <p className="uppercase text-primary text-xs">
                    {event.month}
                  </p>
                  <p className="text-2xl ">{event.day}</p>
                </div>
                <div className="text-xs">
                  <p className="text-xs  font-medium">{event.name}</p>
                  <p style={{ fontSize: 10 }}>{event.place}</p>
                  <p style={{ fontSize: 10 }}>{event.time}</p>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Events;
