import React from "react";
import Breadcrumbs from "../../ui/BreadCrumbs/BreadCrumbs";
import BottomMenu from "../BottomMenu/BottomMenu";
import Header from "../Header/Header";
import NavBar from "../NavBar/NavBar";

function WebsiteLayout({ children, props }) {
  return (
    <div className="relative min-h-screen bg-grey-50 dark:bg-black-900">
      <Header />
      <section className=" pb-20 pt-10  flex-col justify-center items-center ">
        {children}
      </section>
      <BottomMenu />
    </div>
  );
}
export default WebsiteLayout;
