import React, { useState, useEffect, Fragment } from "react";
import WebsiteLayout from "../../../components/Layout/Layout";
import { Transition, Disclosure, Dialog } from "@headlessui/react";
import moment from "moment";
import {
  ArrowCircleUpIcon,
  ChevronDownIcon,
  PlusIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/solid";
import { Tab } from "@headlessui/react";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker/dist/entry.nostyle";
import Axios from "axios";
import { useDropzone } from "react-dropzone";
import customAxios from "../../../components/AxiosInterceptor/AxiosInterceptor";
import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import Breadcrumbs from "../../../ui/BreadCrumbs/BreadCrumbs";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function MyLeaves() {
  const [isOpen, setIsOpen] = useState(false);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [value, setValue] = useState([new Date(), new Date()]);
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState("hello");
  const [error, setError] = useState(null);
  const [selectedLT, setSelectedLT] = useState(leaveTypes[0]);
  const [leaves, setLeaves] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredLeaves, setFilteredLeaves] = useState([]);
  const [files, setFiles] = useState([]);
  const [remarks, setRemarks] = useState();
  const [balances, setBalances] = useState();

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setError(null);
    setSuccess("");
    setIsOpen(false);
  }

  const thumbs = files.map((file) => (
    <div className="w-full" key={file.name}>
      <div className="">
        {/* <img src={file.preview} className="h-10 w-10" /> */}
        <p>{file.name}</p>
      </div>
    </div>
  ));

  function getLeaveBalance() {
    customAxios.get("/selfservice/leave/balance").then((res) => {
      setBalances(res);
    });
  }

  useEffect(() => {
    getLeavesLog();
    getLeaveBalance();
    leaveSelectHandler(selectedLT);
    generateLeaveView(1);
  }, []);

  async function getLeavesLog() {
    customAxios.get("/app/init").then((res) => {
      setLeaveTypes(res.leaveTypes);
      customAxios.get("/selfservice/leaves").then((data, err) => {
        if (data) {
          setLeaves(data);
        }
        if (err) {
          console.log(err);
        }
      });
    });
  }

  function generateLeaveView() {
    return (
      <div className="flex flex-col space-y-3 mt-3">
        {selectedLT == null ? (
          <ul className="flex flex-col space-y-2">
            {leaves && (
              <>
                {leaves.map((leave, index) => (
                  <Disclosure
                    key={index}
                    className="border border-black-200 dark:border-black-800 bg-white dark:bg-black-800 rounded-xl shadow-md"
                  >
                    {({ open }) => (
                      <div>
                        <Disclosure.Button
                          className={`${
                            open ? "bg-primary text-white " : ""
                          } flex flex-row justify-between w-full rounded-t-md p-2 text-xs items-center shadow-md transition-all`}
                        >
                          <div className="flex flex-row justify-between w-full ">
                            <div className="flex flex-row space-x-3">
                              <div className=" flex justify-center items-center  rounded-full">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className={`${
                                    open ? "text-white" : "text-primary"
                                  } h-8 w-8 `}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                              </div>
                              {/* <div className=" flex justify-center items-center rounded-full">
                          {leave.status === "pending" && (
                            <ArrowCircleUpIcon
                              className={`${
                                open
                                  ? "text-white"
                                  : "text-orange-500"
                              } h-10 w-10 `}
                            />
                          )}
                          {leave.status === "approved" && (
                            <CheckCircleIcon
                              className={`${
                                open
                                  ? "text-white"
                                  : "text-orange-500"
                              } h-10 w-10 `}
                            />
                          )}
                          {leave.status === "rejected" && (
                            <XCircleIcon
                              className={`${
                                open
                                  ? "text-white"
                                  : "text-orange-500"
                              } h-10 w-10 `}
                            />
                          )}
                        </div> */}

                              <div className="w-full text-xs text-black-800 dark:text-white font-medium flex flex-col space-y-1 justify-around text-left ">
                                <p>
                                  {moment(leave.from).format("Do MMM YYYY")} -{" "}
                                  {moment(leave.to).format("Do MMM YYYY")}
                                </p>
                                <div
                                  style={{ fontSize: 10 }}
                                  className="flex w-full flex-row space-x-1 justify-around items-center text-black-800 dark:text-black-100"
                                >
                                  <p className="text-left  font-normal">
                                    {getLeaveType(leave.leaveTypeId)}
                                  </p>
                                  <p>|</p>
                                  <li className="font-normal">
                                    {moment(leave.createdAt).format(
                                      "Do MMM YYYY"
                                    )}
                                  </li>
                                  <p>|</p>
                                  <p className="text-primary">Pending</p>
                                </div>
                              </div>
                            </div>

                            <div className=" flex justify-end items-center">
                              <ChevronDownIcon
                                className={`${
                                  open
                                    ? "rotate-180 transform text-white"
                                    : "text-primary"
                                } h-5 w-5 `}
                              />
                            </div>
                          </div>
                          {/* <span>{leave.createdAt}</span>
                    <div className="flex flex-row space-x-3">
                      <div className="text-green-600 bg-success rounded-xl p-1 justify-center items-center  px-2">
                        <p className="text-center text-xs flex items-center justify-center uppercase">
                         Status
                        </p>
                      </div>

                   
                    </div> */}
                        </Disclosure.Button>
                        <Disclosure.Panel className="p-4 grid grid-cols-12 gap-2 text-xs text-black-50 dark:text-black-100">
                          <div className="col-span-4">
                            <ul className="flex flex-col space-y-1">
                              <li>Type:</li>
                              <li>Start Date:</li>
                              <li>End Date:</li>
                              <li>Duration:</li>
                              <li>Remarks:</li>
                              <li>Status:</li>
                              <li>Applied Date:</li>
                            </ul>
                          </div>
                          <div className="col-span-8">
                            <ul className="flex flex-col space-y-1 font-medium">
                              <li>{getLeaveType(leave.leaveTypeId)}</li>
                              <li>
                                {moment(leave.from).format("Do MMMM YYYY")}
                              </li>
                              <li>{moment(leave.to).format("Do MMMM YYYY")}</li>

                              <li>
                                {leave.workingDays
                                  ? `${leave.workingDays} Days`
                                  : "N/A"}
                              </li>
                              <li>{leave.remarks}</li>
                              <li>Pending</li>
                              <li>
                                {moment(leave.createdAt).format("Do MMMM YYYY")}
                              </li>
                            </ul>
                          </div>
                        </Disclosure.Panel>
                      </div>
                    )}
                  </Disclosure>
                ))}
              </>
            )}
          </ul>
        ) : (
          <ul className="flex flex-col  space-y-2">
            {filteredLeaves && (
              <>
                {filteredLeaves.map((leave, index) => (
                  <Disclosure
                    key={index}
                    className="border border-black-200 dark:border-black-800 bg-white dark:bg-black-800 rounded-xl shadow-md"
                  >
                    {({ open }) => (
                      <div>
                        <Disclosure.Button
                          className={`${
                            open ? "bg-primary text-white " : ""
                          } flex flex-row justify-between w-full rounded-t-md p-2 text-xs items-center shadow-md transition-all`}
                        >
                          <div className="flex flex-row justify-between w-full ">
                            <div className="flex flex-row space-x-3">
                              <div className=" flex justify-center items-center  rounded-full">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className={`${
                                    open ? "text-white" : "text-primary"
                                  } h-8 w-8 `}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                              </div>
                              {/* <div className=" flex justify-center items-center rounded-full">
                          {leave.status === "pending" && (
                            <ArrowCircleUpIcon
                              className={`${
                                open
                                  ? "text-white"
                                  : "text-orange-500"
                              } h-10 w-10 `}
                            />
                          )}
                          {leave.status === "approved" && (
                            <CheckCircleIcon
                              className={`${
                                open
                                  ? "text-white"
                                  : "text-orange-500"
                              } h-10 w-10 `}
                            />
                          )}
                          {leave.status === "rejected" && (
                            <XCircleIcon
                              className={`${
                                open
                                  ? "text-white"
                                  : "text-orange-500"
                              } h-10 w-10 `}
                            />
                          )}
                        </div> */}

                              <div className="w-full text-xs text-black-800 dark:text-white font-medium flex flex-col space-y-1 justify-around text-left ">
                                <p>
                                  {moment(leave.from).format("Do MMM YYYY")} -{" "}
                                  {moment(leave.to).format("Do MMM YYYY")}
                                </p>
                                <div
                                  style={{ fontSize: 10 }}
                                  className="flex w-full flex-row space-x-1 justify-around items-center text-black-800 dark:text-black-100"
                                >
                                  <p className="text-left  font-normal">
                                    {getLeaveType(leave.leaveTypeId)}
                                  </p>
                                  <p>|</p>
                                  <li className="font-normal">
                                    {moment(leave.createdAt).format(
                                      "Do MMM YYYY"
                                    )}
                                  </li>
                                  <p>|</p>
                                  <p className="text-primary">Pending</p>
                                </div>
                              </div>
                            </div>

                            <div className=" flex justify-end items-center">
                              <ChevronDownIcon
                                className={`${
                                  open
                                    ? "rotate-180 transform text-white"
                                    : "text-primary"
                                } h-5 w-5 `}
                              />
                            </div>
                          </div>
                          {/* <span>{leave.createdAt}</span>
                    <div className="flex flex-row space-x-3">
                      <div className="text-green-600 bg-success rounded-xl p-1 justify-center items-center  px-2">
                        <p className="text-center text-xs flex items-center justify-center uppercase">
                         Status
                        </p>
                      </div>

                   
                    </div> */}
                        </Disclosure.Button>
                        <Disclosure.Panel className="p-4 grid grid-cols-12 gap-2 text-xs text-black-50 dark:text-black-100">
                          <div className="col-span-4">
                            <ul className="flex flex-col space-y-1">
                              <li>Type:</li>
                              <li>Start Date:</li>
                              <li>End Date:</li>
                              <li>Duration:</li>
                              <li>Remarks:</li>
                              <li>Status:</li>
                              <li>Applied Date:</li>
                            </ul>
                          </div>
                          <div className="col-span-8">
                            <ul className="flex flex-col space-y-1 font-medium">
                              <li>{getLeaveType(leave.leaveTypeId)}</li>
                              <li>
                                {moment(leave.from).format("Do MMMM YYYY")}
                              </li>
                              <li>{moment(leave.to).format("Do MMMM YYYY")}</li>

                              <li>
                                {leave.workingDays
                                  ? `${leave.workingDays} Days`
                                  : "N/A"}
                              </li>
                              <li>{leave.remarks}</li>
                              <li>Pending</li>
                              <li>
                                {moment(leave.createdAt).format("Do MMMM YYYY")}
                              </li>
                            </ul>
                          </div>
                        </Disclosure.Panel>
                      </div>
                    )}
                  </Disclosure>
                ))}
              </>
            )}
          </ul>
        )}
      </div>
    );
  }

  function handleSubmit() {
    setSubmitted(true);
    const data = {
      LeaveTypeId: selectedLT.id,
      DocumentId: 3,
      StartDate: moment(startDate).format("YYYY-MM-DD"),
      EndDate: moment(endDate).format("YYYY-MM-DD"),
      Remarks: remarks,
      Attachments: files,
    };

    console.log(data);

    const form_data = new FormData();

    for (var key in data) {
      const type = typeof data[key];

      if (type === "object") {
        for (var key2 in data[key]) {
          form_data.append(key2, data[key][key2]);
        }
      } else {
        form_data.append(key, data[key]);
      }
    }

    files.forEach((element, index) => {
      form_data.append(`Attachments`, element);
    });

    customAxios.post(`/selfservice/leave/request`, form_data).then(
      (res) => {
        setSuccess(res.message);
        closeModal();
      },
      (error) => {
        setError(error.response?.data.message ?? "unexpected error");
      }
    );
  }

  function leaveSelectHandler(l) {
    setSelectedLT(l);
    getLeavesLog();
    const testArray = [];
    leaves.map((leave, index) => {
      if (leave.leaveTypeId === l?.id) {
        testArray.push(leave);
      }
    });
    setFilteredLeaves(testArray);
  }

  function onChange(item) {
    setStartDate(new Date(item[0]));
    setEndDate(new Date(item[1]));
  }

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  function generateStatCard() {
    {
      balances && (
        <>
          <div className="bg-white text-sm">
            {balances.length >= 1 && (
              <>
                {/* Balance */}
                {JSON.stringify(balances[0].balance)}
                {/* Max  */}
                {JSON.stringify(
                  balances[0].leaveType.leaveGroupDetails[0].maxAccumulate
                )}
              </>
            )}
          </div>
        </>
      );
    }

    const balance = balances?.find(
      (x) => x.leaveTypeId === selectedLT?.id
    )?.balance;

    const max = balances?.find((x) => x.leaveTypeId === selectedLT?.id)
      ?.leaveType?.leaveGroupDetails[0]?.maxAccumulate;

    const taken = max - balance;

    return (
      <div className="shadow-md text-black-900 dark:text-white  bg-white dark:bg-black-600 sticky top-24s space-y-4 rounded-xl w-full p-5 border border-black-200 dark:border-black-500">
        <div className="flex flex-row justify-around items-center">
          <div className="flex flex-col justify-center items-center space-y-1">
            <h1 className="text-2xl font-medium text-primary">
              {taken ? taken : "2"}
            </h1>
            <p className="text-xs">Taken</p>
          </div>
          <div className="flex flex-col justify-center items-center space-y-1">
            <h1 className="text-2xl font-medium text-primary">
              {balance ? balance : "5"}
            </h1>
            <p className="text-xs">Balance</p>
          </div>
          <div className="flex flex-col justify-center items-center space-y-1">
            <h1 className="text-2xl font-medium text-primary">
              {max ? max : "8"}
            </h1>
            <p className="text-xs">Total</p>
          </div>
        </div>
      </div>
    );
  }

  function leaveRequestHandler() {
    openModal();
    setSelectedLT(leaveTypes[0]);
  }

  function getLeaveType(id) {
    return leaveTypes?.find((x) => x.id === id)?.name;
  }
  return (
    <WebsiteLayout>
      <div style={{ zIndex: 10 }} className="">
        <div className="absolute top-0 w-[100vw]  h-[20vh] bg-primary" />
      </div>

      <button
        onClick={() => leaveRequestHandler()}
        style={{ zIndex: 20 }}
        className="bg-primary dark:bg-black-700 active:bg-orange-700 shadow-2xl fixed  flex items-center justify-center bottom-24 right-5 rounded-full p-4"
      >
        <div>
          <PlusIcon className="w-8 h-8 text-white dark:text-primary" />
        </div>
      </button>
      <div className=" relative flex flex-col space-y-4 p-5">
        {/* Attendance */}
        <div>
          {/* Tabs */}
          <div className="w-full   sm:px-0">
            <Tab.Group>
              <div
                style={{ zIndex: 10 }}
                className="shadow-md   sticky top-14 space-y-4 "
              >
                <Tab.List className="flex w-full  justify-between  overflow-x-auto max-w-[90vw] space-x-3  rounded-xl bg-white dark:bg-black-900 p-1">
                  <Tab
                    onClick={() => setSelectedLT(null)}
                    className={({ selected }) =>
                      classNames(
                        " rounded-lg  min-w-fit py-1 px-3 text-xs font-medium leading-5 ",
                        " focus:outline-none",
                        selected
                          ? "bg-primary text-white shadow"
                          : "text-primary hover:bg-white/[0.12] hover:text-white"
                      )
                    }
                  >
                    All
                  </Tab>
                  {leaveTypes.map((leave, index) => (
                    <Tab
                      key={index}
                      onClick={() => leaveSelectHandler(leave)}
                      className={({ selected }) =>
                        classNames(
                          " rounded-lg  min-w-fit py-1 px-3 text-xs font-medium leading-5 ",
                          " focus:outline-none",
                          selected
                            ? "bg-primary text-white shadow"
                            : "text-primary hover:bg-white/[0.12] hover:text-white"
                        )
                      }
                    >
                      {leave.name}
                    </Tab>
                  ))}
                </Tab.List>

                {selectedLT?.name && <>{generateStatCard()}</>}
              </div>
              {generateLeaveView()}
            </Tab.Group>
          </div>
        </div>
      </div>
      {/* Add new leave Dialog Panel */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black-900 bg-opacity-80" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto ">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-100 translate-y-[200px]"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0"
              >
                <Dialog.Panel className="w-full min-h-[40vh] max-w-screen transform overflow-hidden rounded-2xl bg-white dark:bg-black-800 border border-white dark:border-black-600 p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex flex-row justify-between items-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-black-900 dark:text-white"
                    >
                      Request a Leave
                    </Dialog.Title>
                    <XIcon
                      onClick={closeModal}
                      className="w-4 h-4 text-black-700 dark:text-white active:text-primary"
                    />
                  </div>

                  <div className="mt-4 flex flex-col text-xs space-y-5 ">
                    {/* Leave Type */}
                    <div className="flex flex-col space-y-2">
                      {/* Error and Success Server Message */}
                      {submitted && error && (
                        <div
                          className={`${
                            error == null ? "bg-success" : "bg-danger"
                          } p-2 rounded-xl`}
                        >
                          <div className="flex flex-row space-x-3 justify-start items-center">
                            <div>
                              {error && (
                                <XCircleIcon className="h-10 w-10 text-red-700" />
                              )}
                            </div>
                            <div className="flex-col">
                              <h1 className="text-sm font-medium">
                                {error ? "Error" : "Successful"}
                              </h1>
                              <div>
                                {error === null ? `${success}` : `${error}`}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <p className="text-sm text-black-900 dark:text-white font-medium">
                        Select Leave type
                      </p>
                      <Tab.Group>
                        <Tab.List className="flex justify-start items-center overflow-x-auto bg-orange-50 dark:bg-black-900 max-w-[90vw] space-x-3 rounded-xl  p-1">
                          {leaveTypes?.map((leave, index) => (
                            <Tab
                              key={index}
                              onClick={() => setSelectedLT(leave)}
                              className={({ selected }) =>
                                classNames(
                                  " rounded-lg  min-w-fit py-1 px-3 text-xs font-medium leading-5  ",
                                  "focus:outline-none ",
                                  selected
                                    ? "bg-primary text-white shadow font-medium"
                                    : "text-primary hover:bg-white/[0.12] hover:text-white"
                                )
                              }
                            >
                              {leave.name}
                            </Tab>
                          ))}
                        </Tab.List>
                      </Tab.Group>
                    </div>
                    {/* Select Start Date and End Date */}
                    <div className="grid grid-cols-12 gap-3">
                      <div className="grid grid-cols-10 col-span-10">
                        <div className="col-span-3 flex flex-col justify-center items-start space-y-3 text-black-600 dark:text-white text-sm font-medium">
                          <div className="">Start Date</div>
                          <div className="">End Date</div>
                        </div>
                        <div className="col-span-7 ml-3 flex flex-col text-black-900 dark:text-white  justify-center items-start space-y-3 text-sm">
                          <div className="">
                            {moment(startDate).format("Do MMMM YYYY")}
                          </div>
                          <div className="">
                            {moment(endDate).format("Do MMMM YYYY")}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2">
                        <div className="flex justify-center  w-full h-full items-center">
                          <DateTimeRangePicker
                            onChange={(item) => onChange(item)}
                            value={value}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Add Remarks */}
                    <div className="flex flex-col space-y-2 ">
                      <h1 className="text-sm text-black-600 dark:text-white  font-medium">
                        Add Remarks
                      </h1>
                      <input
                        type="text"
                        onChange={(e) => setRemarks(e.target.value)}
                        className="p-3 border border-black-200 dark:border-black-500 bg-black-100 text-black-800 dark:text-white dark:bg-black-800"
                      />
                    </div>
                    {/* Add files */}
                    <div className="flex flex-col space-y-2">
                      <h1 className="text-sm text-black-600 dark:text-white  font-medium">
                        Attachments
                      </h1>
                      <div
                        {...getRootProps({ className: "dropzone" })}
                        className="border dark:border-black-700 dark:bg-black-600 bg-black-200 border-black-300 text-black-600 dark:text-white  focus:outline-none p-2 rounded"
                      >
                        <input {...getInputProps()} />
                        <p>
                          Drag 'n' drop some files here, or click to select
                          files
                        </p>
                      </div>
                      <aside className="flex flex-row space-x-3 w-full max-w-[90vw] overflow-auto">
                        {thumbs}
                      </aside>
                    </div>
                  </div>

                  <button
                    disabled={submitted ? true : false}
                    type="button"
                    className="inline-flex w-full text-xs justify-center mt-4 rounded-xl border border-transparent bg-primary px-4 py-2 font-medium text-white hover:bg-orange-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-orange-500 focus-visible:ring-offset-2"
                    onClick={() => handleSubmit()}
                  >
                    Apply
                  </button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </WebsiteLayout>
  );
}

export default MyLeaves;
