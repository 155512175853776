import React, { useState, useEffect, Fragment } from "react";
import WebsiteLayout from "../../../components/Layout/Layout";
import moment from "moment/moment";
import { Listbox, Transition, Disclosure, Tab } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DownloadIcon,
} from "@heroicons/react/solid";
import customAxios from "../../../components/AxiosInterceptor/AxiosInterceptor";
import Heading from "../../../ui/Heading/Heading";

const year = [2022];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
];

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "MVR",
});

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const dummySalary = {
  basicPay: 9500,
  travelAllowance: +1000,
  jobExperienceAllowance: +8000,
  technicalCertificationAllowance: +2500,
  professionalAllowance: +3000,
  attendanceBonus: +500,
  pension: -500,
  lateFine: -300,
};

function MySalary() {
  const [time, setTime] = useState();
  const [selectedYear, setSelectedYear] = useState(year[0]);
  const [selectedMonth, setSelectedMonth] = useState(months[0]);

  return (
    <WebsiteLayout>
      <div style={{ zIndex: 10 }} className="">
        <div className="absolute top-0 w-[100vw]  h-[30vh] bg-primary" />
      </div>

      <div className=" relative flex flex-col space-y-1 p-5">
        <div className="flex z-40 bg-white rounded-xl p-1 flex-row space-x-2 justify-start items-center">
          {/* Year Select */}
          <div className="w-fit">
            <Listbox value={selectedYear} onChange={setSelectedYear}>
              <div className="relative ">
                <Listbox.Button className="relative w-full text-sm cursor-default rounded-lg bg-primaryLight py-1 pl-3 pr-10 text-left  focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                  <span className="block truncate text-white text-sm">
                    {selectedYear}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 z-50 max-h-60 w-full overflow-auto rounded-xl bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
                    {year.map((year, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          `relative cursor-default text-sm select-none py-2  px-4 ${
                            active
                              ? "bg-amber-100 font-medium text-amber-900 "
                              : "text-black-900 "
                          }`
                        }
                        value={year}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-normal" : "font-medium"
                              }`}
                            >
                              {year}
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
          {/* Month Select */}
          <Tab.Group>
            <Tab.List className="flex w-full justify-between bg  overflow-x-auto max-w-[90vw] space-x-1   bg-orange-50 ">
              {months.map((month, index) => (
                <Tab
                  key={index}
                  className={({ selected }) =>
                    classNames(
                      " rounded-lg  min-w-fit py-1 px-3 text-xs font-medium leading-5 ",
                      "ring-white ring-opacity-60 ring-offset-2 ring-offset-orange-400 focus:outline-none focus:ring-2",
                      selected
                        ? "bg-primary text-white shadow"
                        : "text-primary hover:bg-white/[0.12] hover:text-white"
                    )
                  }
                >
                  {month}
                </Tab>
              ))}
            </Tab.List>
          </Tab.Group>
        </div>
        <div className="flex flex-col text-white dark:text-white z-20 py-3 items-center justify-center">
          <h5 className="text-xs uppercase ">Total</h5>
          <h1 className="text-3xl  font-medium">
            {formatter.format(
              dummySalary.basicPay +
                dummySalary.travelAllowance +
                dummySalary.jobExperienceAllowance +
                dummySalary.technicalCertificationAllowance +
                dummySalary.professionalAllowance +
                dummySalary.attendanceBonus +
                dummySalary.pension +
                dummySalary.lateFine
            )}
          </h1>
        </div>
        {/* Attendance Card */}
        <div className="shadow-md z-30 bg-white dark:bg-black-800 flex flex-col space-y-4 rounded-xl w-full p-5 border border-black-200 dark:border-black-800">
          <div className="flex flex-col font-medium justify-start items-start border-b border-b-black-100 dark:border-black text-black dark:text-white pb-2 text-left text-sm">
            <h1>Pay Slip Details</h1>
          </div>
          {/* body */}
          <div className="text-xs text-black-900 dark:text-white flex flex-col space-y-5">
            <div className="flex justify-between">
              <h1>Salary</h1>
              <p className="font-medium text-primary">
                {formatter.format(dummySalary.basicPay)}
              </p>
            </div>
            <div className="flex justify-between">
              <h1>Travel Allowance</h1>
              <p
                className={`font-medium ${
                  dummySalary.travelAllowance < 0
                    ? "text-danger"
                    : "text-success"
                }`}
              >
                {dummySalary.travelAllowance > 0 ? "+ " : ""}
                {formatter.format(dummySalary.travelAllowance)}
              </p>
            </div>
            <div className="flex justify-between">
              <h1>Job Experience Allowance</h1>
              <p
                className={`font-medium ${
                  dummySalary.jobExperienceAllowance < 0
                    ? "text-danger"
                    : "text-success"
                }`}
              >
                {dummySalary.jobExperienceAllowance > 0 ? "+ " : ""}
                {formatter.format(dummySalary.jobExperienceAllowance)}
              </p>
            </div>
            <div className="flex justify-between">
              <h1>Technical Certification Allowance</h1>
              <p
                className={`font-medium ${
                  dummySalary.technicalCertificationAllowance < 0
                    ? "text-danger"
                    : "text-success"
                }`}
              >
                {dummySalary.technicalCertificationAllowance > 0 ? "+ " : ""}
                {formatter.format(dummySalary.technicalCertificationAllowance)}
              </p>
            </div>
            <div className="flex justify-between">
              <h1>Professional Allowance</h1>
              <p
                className={`font-medium ${
                  dummySalary.professionalAllowance < 0
                    ? "text-danger"
                    : "text-success"
                }`}
              >
                {dummySalary.professionalAllowance > 0 ? "+ " : ""}
                {formatter.format(dummySalary.professionalAllowance)}
              </p>
            </div>
            <div className="flex justify-between">
              <h1>Attendance Bonus</h1>
              <p
                className={`font-medium ${
                  dummySalary.attendanceBonus < 0
                    ? "text-danger"
                    : "text-success"
                }`}
              >
                {dummySalary.attendanceBonus > 0 ? "+ " : ""}
                {formatter.format(dummySalary.attendanceBonus)}
              </p>
            </div>
            <div className="flex justify-between">
              <h1>Pension</h1>
              <p
                className={`font-medium ${
                  dummySalary.pension < 0 ? "text-danger" : "text-success"
                }`}
              >
                {dummySalary.pension > 0 ? "+ " : ""}
                {formatter.format(dummySalary.pension)}
              </p>
            </div>
            <div className="flex justify-between">
              <h1>Late Fine</h1>
              <p
                className={`font-medium ${
                  dummySalary.lateFine < 0 ? "text-danger" : "text-success"
                }`}
              >
                {dummySalary.lateFine > 0 ? "+ " : ""}
                {formatter.format(dummySalary.lateFine)}
              </p>
            </div>
          </div>
          {/* Download Pay Slip */}
          <button className="bg-primary p-2 mt-4 text-xs rounded-xl flex text-white dark:text-black-200 justify-center items-center flex-row shadow-md">
            <DownloadIcon className="w-5 h-5 mr-3  " />
            Download Payslip
          </button>
        </div>
      </div>
    </WebsiteLayout>
  );
}

export default MySalary;
