import React, { useState, useEffect, useRef } from "react";
import WebsiteLayout from "../../../components/Layout/Layout";
import ShiftCalendar from "../../../components/ShiftCalendar/ShiftCalendar";
import SubHeading from "../../../ui/SubHeading/SubHeading";
import { isSameDay } from "date-fns";
import moment from "moment";
import Card from "../../../ui/Card/Card";

const shiftTypes = [
  {
    id: 1,
    name: "Morning Shift",
    startTime: "04:30",
    endTime: "14:00",
    color: "#b9d66a",
  },
  {
    id: 2,
    name: "Afternoon Shift",
    startTime: "14:30",
    endTime: "22:00",
    color: "#52cca9",
  },
  {
    id: 3,
    name: "Night Shift",
    startTime: "22:00",
    endTime: "04:30",
    color: "#56a3f5",
  },
  {
    id: 4,
    name: "Off Day",
    startTime: null,
    endTime: null,
    color: "#6e6e70",
  },
];

const shifts = [
  {
    date: new Date(2022, 9, 1),
    shiftType: 1,
  },
  {
    date: new Date(2022, 9, 2),
    shiftType: 2,
  },
  {
    date: new Date(2022, 9, 3),
    shiftType: 2,
  },
  {
    date: new Date(2022, 9, 4),
    shiftType: 2,
  },
  {
    date: new Date(2022, 9, 5),
    shiftType: 3,
  },
  {
    date: new Date(2022, 9, 6),
    shiftType: 4,
  },
  {
    date: new Date(2022, 9, 1),
    shiftType: 1,
  },
  {
    date: new Date(2022, 9, 2),
    shiftType: 2,
  },
  {
    date: new Date(2022, 9, 3),
    shiftType: 2,
  },
  {
    date: new Date(2022, 9, 4),
    shiftType: 2,
  },
  {
    date: new Date(2022, 9, 5),
    shiftType: 3,
  },
  {
    date: new Date(2022, 9, 6),
    shiftType: 4,
  },
  {
    date: new Date(2022, 9, 7),
    shiftType: 1,
  },
  {
    date: new Date(2022, 9, 8),
    shiftType: 2,
  },
  {
    date: new Date(2022, 9, 9),
    shiftType: 2,
  },
  {
    date: new Date(2022, 9, 10),
    shiftType: 2,
  },
  {
    date: new Date(2022, 9, 11),
    shiftType: 3,
  },
  {
    date: new Date(2022, 9, 12),
    shiftType: 4,
  },
  {
    date: new Date(2022, 9, 13),
    shiftType: 2,
  },
  {
    date: new Date(2022, 9, 14),
    shiftType: 2,
  },
  {
    date: new Date(2022, 9, 15),
    shiftType: 2,
  },
  {
    date: new Date(2022, 9, 16),
    shiftType: 4,
  },
  {
    date: new Date(2022, 9, 17),
    shiftType: 1,
  },
  {
    date: new Date(2022, 9, 18),
    shiftType: 3,
  },
  {
    date: new Date(2022, 9, 19),
    shiftType: 2,
  },
  {
    date: new Date(2022, 9, 20),
    shiftType: 2,
  },
  {
    date: new Date(2022, 9, 21),
    shiftType: 4,
  },
  {
    date: new Date(2022, 9, 22),
    shiftType: 3,
  },
  {
    date: new Date(2022, 9, 23),
    shiftType: 3,
  },
  {
    date: new Date(2022, 9, 24),
    shiftType: 3,
  },
  {
    date: new Date(2022, 9, 25),
    shiftType: 2,
  },
  {
    date: new Date(2022, 9, 26),
    shiftType: 2,
  },
  {
    date: new Date(2022, 9, 27),
    shiftType: 3,
  },
  {
    date: new Date(2022, 9, 28),
    shiftType: 4,
  },
  {
    date: new Date(2022, 9, 29),
    shiftType: 3,
  },
  {
    date: new Date(2022, 9, 30),
    shiftType: 3,
  },
  {
    date: new Date(2022, 9, 31),
    shiftType: 1,
  },
];

function MyShifts() {
  const today = new Date();
  const [time, setTime] = useState();
  const [Greeting, setGreeting] = useState();

  useEffect(() => {
    // loadProfile();
    const interval = setInterval(function () {
      setTime(moment().format("h:mm:ss a"));
    }, 1000);
  }, []);

  function findTodaysShift() {
    let todayShift = shifts?.find((s) => isSameDay(today, s.date));
    return shiftTypes.find((st) => st.id === todayShift?.shiftType)?.name;
  }

  function findTodaysSchedule() {
    let todayShift = shifts?.find((s) => isSameDay(today, s.date));
    let startTime = shiftTypes.find(
      (st) => st.id === todayShift?.shiftType
    )?.startTime;
    let endTime = shiftTypes.find(
      (st) => st.id === todayShift?.shiftType
    )?.endTime;
    const data = {
      startTime: startTime,
      endTime: endTime,
    };
    return (
      <div className="text-xs text-black-600 space-y-3 flex flex-col">
        <div className="flex flex-row space-x-3 justify-between">
          <p className="font-medium text-black-400">Start Time:</p>
          <span>{data.startTime}</span>
        </div>

        <div className="flex flex-row space-x-3 justify-between">
          <p className="font-medium text-black-400">End Time:</p>
          <span>{data.endTime}</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <WebsiteLayout>
        <div className="absolute top-0 w-[100vw] z-10 h-[13vh] bg-primary" />
        <div className=" space-y-5 z-50 ">
          <div className="px-5 sticky z-50 top-16">
            <Card>
              <div className="flex flex-row  justify-between items-center">
                {/* Greeting */}
                <div className="flex flex-col justify-center text-black-900 dark:text-black-100 space-y-2 ">
                  <p className="text-xs">Today's Shift</p>
                  <p className="font-medium text-xl">{findTodaysShift()}</p>
                  {/* Check In Status */}
                </div>
                {/* Profile Picture */}
                <div className="flex justify-end items-end">
                  <div className="text-primary">{findTodaysSchedule()}</div>
                </div>
              </div>
            </Card>
          </div>
          {/* All Shift Types */}
          <div className="space-y-3 pt-3">
            <div className="px-5">
              <SubHeading heading="Shift Types" />
            </div>
            <div className="flex flex-row w-[100vw] text-xs overflow-auto pl-5 pb-2 space-x-2 ">
              {shiftTypes.map((st, i) => (
                <div
                  className="p-2 h-14 border-l-4  bg-white flex justify-center text-black-900 shadow flex-col min-w-[115px] rounded-xl"
                  style={{ borderLeftColor: st.color }}
                  key={i}
                >
                  <p className="font-medium">{st.name}</p>
                  <p className="text-black-500">
                    {st.startTime} - {st.endTime}
                  </p>
                </div>
              ))}
            </div>
          </div>
          {/* Shift Calendar*/}
          <div className="px-5 ">
            <ShiftCalendar shifts={shifts} shiftTypes={shiftTypes} />
          </div>
        </div>
      </WebsiteLayout>
    </>
  );
}

export default MyShifts;
