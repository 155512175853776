import { RssIcon } from "@heroicons/react/solid";
import moment from "moment";
import React from "react";
import WebsiteLayout from "../../../components/Layout/Layout";
import Card from "../../../ui/Card/Card";
import SubHeading from "../../../ui/SubHeading/SubHeading";
import { isSameMonth, format, formatISO } from "date-fns";
import Holiday from "../../../assets/images/holiday.png";

const months = [
  new Date("2022-01-01T09:00:00"),

  new Date("2022-02-01T09:00:00"),

  new Date("2022-03-01T09:00:00"),

  new Date("2022-04-01T09:00:00"),

  new Date("2022-05-01T09:00:00"),

  new Date("2022-06-01T09:00:00"),

  new Date("2022-07-01T09:00:00"),

  new Date("2022-08-01T09:00:00"),

  new Date("2022-09-01T09:00:00"),

  new Date("2022-10-01T09:00:00"),

  new Date("2022-11-01T09:00:00"),

  new Date("2022-12-01T09:00:00"),
];

const holidays = [
  {
    name: "National Day",
    date: new Date("2022-01-10T09:00:00"),
    type: "Public Holiday",
  },
  {
    name: "Islamic Day",
    date: new Date("2022-04-10T09:00:00"),
    type: "National Holiday",
  },
  {
    name: "Republic Day of Maldives",
    date: new Date("2022-09-20T09:00:00"),
    type: "National Holiday",
  },
];

function sortMonthsAndHolidays() {
  let currentMonth = new Date();
  let monthHolidays = [];
  months.map((month) => {
    let holidaysEachMonth = holidays.filter((h) => isSameMonth(month, h.date));
    monthHolidays.push({
      month: month,
      holidays: holidaysEachMonth,
    });
  });

  let currentMonthHolidays = monthHolidays.filter((mh) =>
    isSameMonth(mh.month, currentMonth)
  );
  console.log(currentMonthHolidays);
  return (
    <>
      <SubHeading heading={"Current Month"} />
      {currentMonthHolidays?.map((holidays, i) => (
        <>
          {holidays.holidays.map((h, i) => (
            <Card key={i}>
              <div className="flex text-primary flex-row justify-between">
                <div className="flex flex-row justify-start items-center space-x-3">
                  <img src={Holiday} className="h-8 w-8 " />
                  <div className="text-sm">
                    <h1 className="font-medium">{h.name}</h1>
                    <p className="text-xs text-black-600">
                      {format(h.date, "d MMMM")}
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </>
      ))}
      {monthHolidays.map((mh, i) => (
        <div key={i} className="space-y-3">
          <SubHeading heading={format(mh.month, "MMMM")} />
          {mh.holidays.map((holiday, i) => (
            <Card key={i}>
              <div className="flex flex-row justify-between">
                <div className="flex flex-row justify-start items-center space-x-3">
                  <img src={Holiday} className="h-8 w-8 " />
                  <div className="text-sm">
                    <h1 className="font-medium">{holiday.name}</h1>
                    <p className="text-xs text-black-600">
                      {format(holiday.date, "d MMMM")}
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </div>
      ))}
    </>
  );
}

function MyHolidays() {
  return (
    <WebsiteLayout>
      <div className="absolute top-0 w-[100vw] z-10 h-[13vh] bg-primary" />
      <div className="fixed top-0 w-[100vw] z-10 h-[13vh] bg-primary" />
      <div className="fixed z-50 top-10 bg-white h-[85vh] overflow-auto w-full">
        <div className="px-5 flex flex-col space-y-3 mt-3">
          {sortMonthsAndHolidays()}
        </div>
      </div>
    </WebsiteLayout>
  );
}

export default MyHolidays;
