import React from "react";
import BottomMenu from "../BottomMenu/BottomMenu";
import Header from "../Header/Header";
import NavBar from "../NavBar/NavBar";

function WebsiteLayout({ children, props }) {
  return (
    <div className="relative min-h-screen bg-grey-50  dark:bg-black-900 ">
      <Header />
      <div className="absolute top-0 w-[100vw] z-0 h-[20vh] bg-primary " />
      <section className=" flex-col  pt-14 pb-10  relative flex   ">
        {children}
      </section>
      <BottomMenu />
    </div>
  );
}
export default WebsiteLayout;
